import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import axios from "axios";
import {
  SpecialMassTemplate,
  RecurringMassTemplate,
  MassState,
} from "vincent-types/models";

const INITIAL_STATE: MassState = {
  recurring: {
    data: [],
  },
  special: {
    data: [],
  },
};

export const getRecurringMassesAsync = createAsyncThunk(
  "masses/getRecurringMassesAsync",
  async () => {
    const resp = await axios.get("/api/mass/templates/recurring");
    return resp.data;
  }
);

export const getSpecialMassesAsync = createAsyncThunk(
  "masses/getSpecialMassesAsync",
  async () => {
    const resp = await axios.get("/api/mass/templates/special");
    return resp.data;
  }
);

export const massSlice = createSlice({
  name: "masses",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getRecurringMassesAsync.fulfilled.toString()]: (state, action) => {
      state.recurring.data = action.payload as RecurringMassTemplate[];
      state.recurring.refreshed = moment().toLocaleString();
    },
    [getRecurringMassesAsync.rejected.toString()]: (state, action) => {
      state.recurring.error =
        action.payload?.err?.message || "Error retrieving recurring masses";
    },
    [getRecurringMassesAsync.pending.toString()]: (state, action) => {
      state.recurring.data = [];
      state.recurring.refreshed = undefined;
      state.recurring.error = undefined;
    },
    [getSpecialMassesAsync.fulfilled.toString()]: (state, action) => {
      state.special.data = action.payload as SpecialMassTemplate[];
      state.special.refreshed = moment().toLocaleString();
    },
    [getSpecialMassesAsync.rejected.toString()]: (state, action) => {
      state.special.error =
        action.payload?.err?.message || "Error retrieving special masses";
    },
    [getSpecialMassesAsync.pending.toString()]: (state, action) => {
      state.special.data = [];
      state.special.refreshed = undefined;
      state.special.error = undefined;
    },
  },
});

// export const { logout, setPreference } = massSlice.actions;

export default massSlice.reducer;
