import { useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";

interface UnavailableDatesProps {
  onNext: (startDate: string, endDate: string, notes: string) => void;
  onCancel: () => void;
  startDate: string;
  endDate: string;
}

function UnavailableDates(props: UnavailableDatesProps) {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [notes, setNotes] = useState("");

  const nextDisabled = (): boolean => {
    return startDate.length === 0 || endDate.length === 0;
  };

  return (
    <Card>
      <Card.Body className="new-intention-card">
        <Card.Title>Unavailable Dates</Card.Title>
        <Form>
          <Row md={3}>
            <Col>
              <Form.Group controlId="formFirstName">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  value={startDate}
                  type="date"
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formLastName">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  value={endDate}
                  type="date"
                  onChange={(event) => setEndDate(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row md={2}>
            <Col>
              <Form.Group controlId="formLastName">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={notes}
                  type="text"
                  onChange={(event) => setNotes(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div className="new-intention-button-group">
          <Button variant="outline-secondary" onClick={props.onCancel}>
            Cancel
          </Button>
          <div className="new-intention-button-spacer" />
          <Button
            disabled={nextDisabled()}
            variant="success"
            onClick={() => props.onNext(startDate, endDate, notes)}
          >
            Next
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default UnavailableDates;
