import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AdminState,
  PermissionGroup,
  User,
  UserActivity,
} from "vincent-types/models";
import axios from "axios";
import moment from "moment";

const INITIAL_STATE: AdminState = {
  users: {
    data: [],
  },
  activity: {
    data: [],
  },
  permissions: {
    data: [],
  },
};

export const getPermissionsAsync = createAsyncThunk(
  "admin/getPermissionsAsync",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get("/api/permissions");
      return resp.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const getUsersAsync = createAsyncThunk(
  "users/getUsersAsync",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get("/api/users");
      return resp.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const getActivityAsync = createAsyncThunk(
  "users/getActivityAsync",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get("/api/users/activity");
      return resp.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const adminSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getUsersAsync.pending.toString()]: (state, action) => {
      state.users.refreshed = undefined;
      state.users.error = undefined;
      state.users.data = [];
    },
    [getUsersAsync.rejected.toString()]: (state, action) => {
      state.users.error =
        action.payload?.err?.message || "Error retrieving users";
    },
    [getUsersAsync.fulfilled.toString()]: (state, action) => {
      state.users.data = action.payload as User[];
      state.users.refreshed = moment().toLocaleString();
    },
    [getActivityAsync.pending.toString()]: (state, action) => {
      state.activity.refreshed = undefined;
      state.activity.error = undefined;
      state.activity.data = [];
    },
    [getActivityAsync.rejected.toString()]: (state, action) => {
      state.activity.error =
        action.payload?.err?.message || "Error retrieving user activity";
    },
    [getActivityAsync.fulfilled.toString()]: (state, action) => {
      state.activity.data = action.payload as UserActivity[];
      state.activity.refreshed = moment().toLocaleString();
    },
    [getPermissionsAsync.pending.toString()]: (state, action) => {
      state.permissions.refreshed = undefined;
      state.permissions.error = undefined;
      state.permissions.data = [];
    },
    [getPermissionsAsync.rejected.toString()]: (state, action) => {
      state.permissions.error =
        action.payload?.err?.message || "Error retrieving permissions";
    },
    [getPermissionsAsync.fulfilled.toString()]: (state, action) => {
      state.permissions.data = action.payload as PermissionGroup[];
      state.permissions.refreshed = moment().toLocaleString();
    },
  },
});

export default adminSlice.reducer;
