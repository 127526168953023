import PortraitWeek from "../../components/portrait-week";
import ActionBar from "../../components/action-bar";
import WeekHeader from "../../components/week-header";
import IntentionEditModal from "../../modals/intention-edit-modal";
import moment from "moment";
import "./styles.css";
import { useEffect, useState } from "react";
import { selectDate, getMassesAsync } from "../../redux/week-slice";
import CalendarModal from "../../components/calendar-modal";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "vincent-types/models";
import { getPriestsAsync } from "../../redux/priest-slice";
import { Mass } from "vincent-types/models";
import { Permissions } from "vincent-types/enums";

function HomePage() {
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const user = useSelector((state: AppState) => state.user);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showIntentionEditModal, setShowIntentionEditModal] = useState(false);
  const [selectedMass, setSelectedMass] = useState<Mass | undefined>(undefined);
  const dates = useSelector((state: AppState) => state.week.dates);
  const dispatch = useDispatch();

  const onCalendarClick = () => {
    setShowCalendarModal(true);
  };

  const onSelectDate = (date: string) => {
    setSelectedDate(date);
    dispatch(selectDate(date));
    setShowCalendarModal(false);
  };

  const onRefresh = () => {
    dispatch(selectDate(selectedDate));
  };

  const onHideIntentionEditModal = () => {
    setSelectedMass(undefined);
    setShowIntentionEditModal(false);
  };

  const onSelectIntention = (mass: Mass) => {
    if (
      mass.intention ||
      user.permissions.some((p) =>
        [
          Permissions.EditIntention.toString(),
          Permissions.DeleteIntention.toString(),
        ].includes(p)
      )
    ) {
      setSelectedMass(mass);
      setShowIntentionEditModal(true);
    }
  };

  const onSaveEdit = () => {
    onHideIntentionEditModal();
    dispatch(selectDate(selectedDate));
  };

  useEffect(() => {
    dispatch(selectDate(undefined));
    dispatch(getPriestsAsync());
  }, [dispatch]);
  useEffect(() => {
    if (dates && dates.start && dates.end) {
      dispatch(getMassesAsync({ start: dates.start, end: dates.end }));
    }
  }, [dates, dispatch]);

  return (
    <>
      <ActionBar
        title="Intentions"
        to="/new-intention"
        buttonTitle="Create"
        buttonPermissions={[Permissions.CreateIntention]}
      />
      {dates && (
        <>
          <WeekHeader
            onCalendarClick={onCalendarClick}
            onChangeDate={setSelectedDate}
            onRefresh={onRefresh}
          />
          <div className="home-week-container">
            <PortraitWeek onSelect={onSelectIntention} />
          </div>
        </>
      )}
      {selectedMass && (
        <IntentionEditModal
          show={showIntentionEditModal}
          onHide={() => onSaveEdit()}
          mass={selectedMass}
          onSave={onSaveEdit}
        />
      )}
      {dates && (
        <CalendarModal
          show={showCalendarModal}
          onHide={() => setShowCalendarModal(false)}
          date={dates.start}
          onSelectDate={onSelectDate}
        />
      )}
    </>
  );
}

export default HomePage;
