import { BsXCircle } from "react-icons/bs";
import { formatDateTime } from "../../utils/stringFunctions";
import { CgCross } from "react-icons/cg";
import { RequestedIntention } from "vincent-types/models";
import "./styles.css";

interface IntentionCardProps {
  intention: RequestedIntention;
  onDelete: (intention: RequestedIntention) => void;
}

function IntentionCard(props: IntentionCardProps) {
  const { intention } = props;
  return (
    <div className="intention-card-parent">
      <div
        onClick={() => props.onDelete(intention)}
        className="intention-card-remove-circle"
      >
        <BsXCircle size="30" />
      </div>
      <div className="intention-card-container">
        <div style={{ margin: "auto" }}>
          <div>
            {intention?.subject}
            {intention.deceased ? (
              <CgCross className="intention-card-deceased-icon" />
            ) : null}
          </div>
          {formatDateTime(intention?.dateTime)}
        </div>
      </div>
    </div>
  );
}

export default IntentionCard;
