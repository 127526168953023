import { useHistory } from "react-router-dom";
import { useState } from "react";
import { ConflictingMass } from "vincent-types/models";
import Conflicts from "./conflicts";
import UnavailableDates from "./dates";
import Summary from "./summary";
import _ from "lodash";
import "./styles.css";

export interface UnavailableDatesCart {
  unavailableDates: {
    start: string;
    end: string;
    notes: string;
  };
  conflicts: ConflictingMass[];
}

const INITIAL_CART: UnavailableDatesCart = {
  unavailableDates: {
    start: "",
    end: "",
    notes: "",
  },
  conflicts: [],
};

function NewUnavailableDatesPage() {
  const history = useHistory();
  const [cart, setCart] = useState(INITIAL_CART);
  const [step, setStep] = useState(0);

  const saveDates = (startDate: string, endDate: string, notes: string) => {
    let newCart = _.cloneDeep(cart);
    newCart.unavailableDates.start = startDate;
    newCart.unavailableDates.end = endDate;
    newCart.unavailableDates.notes = notes;
    setCart(newCart);
    setStep(1);
  };

  const saveConflicts = (conflicts: ConflictingMass[]) => {
    let newCart = _.cloneDeep(cart);
    newCart.conflicts = conflicts;
    setCart(newCart);
    setStep(2);
  };

  const cancel = () => {
    history.push("/unavailable-dates");
  };

  const back = () => {
    setStep(step - 1);
  };

  return (
    <div>
      <h3>New Unavailable Dates</h3>
      {step === 0 && (
        <UnavailableDates
          startDate={cart.unavailableDates.start}
          endDate={cart.unavailableDates.end}
          onNext={saveDates}
          onCancel={cancel}
        />
      )}
      {step === 1 && (
        <Conflicts
          startDate={cart.unavailableDates.start}
          endDate={cart.unavailableDates.end}
          onNext={saveConflicts}
          onBack={back}
          onCancel={cancel}
        />
      )}
      {step === 2 && <Summary cart={cart} onCancel={cancel} onBack={back} />}
    </div>
  );
}

export default NewUnavailableDatesPage;
