import { useState, useRef, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { IntentionReportEntry, Mass } from "vincent-types/models";
import moment from "moment";
import "./styles.css";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { IntentionsByRequestorPrintout } from "../../../components/print-components/intentions-by-requestor";
import { IntentionsReportTable } from "../../../components/intention-report";
import { handleAxiosError } from "../../../utils/network";

interface Requestor {
  name: string;
  phone: string;
  languages: string[];
}

function IntentionsByRequestor() {
  const [intentions, setIntentions] = useState<IntentionReportEntry[]>([]);
  const [selectedRequestors, setSelectedRequestors] = useState<Requestor[]>([]);
  const [filter, setFilter] = useState("");
  const [requestors, setRequestors] = useState<Requestor[]>([]);
  const [allRequestors, setAllRequestors] = useState<Requestor[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestorsLoading, setRequestorsLoading] = useState(false);
  const [error, setError] = useState("");
  const [report, setReport] = useState<{
    intentions: IntentionReportEntry[];
    total: string;
    printDateTime?: string;
    requestor?: string;
  }>({
    intentions: [],
    total: "" + 0,
  });
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleFilter = (event: any) => {
    const { value } = event.target;
    if (value?.length >= 3) {
      setRequestors(
        allRequestors.filter((r) =>
          r.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setRequestors(allRequestors);
    }
    setFilter(value);
  };

  const handleCheck = (event: any) => {
    const value: Requestor = JSON.parse(event.target.value as string);
    const checked = event.target.checked as boolean;
    let newSelection: Requestor[] = [...selectedRequestors];
    if (checked) {
      if (
        !newSelection.find(
          (n) => n.name === value.name && n.phone === value.phone
        )
      ) {
        newSelection.push(value);
      }
    } else {
      newSelection = newSelection.filter(
        (n) => n.phone !== value.phone || n.name !== value.name
      );
    }
    setSelectedRequestors(newSelection);
  };

  const getRequestors = async () => {
    setRequestors([]);
    setRequestorsLoading(true);
    setError("");
    try {
      const resp = await axios.get<Requestor[]>(`/api/requestors`);
      setAllRequestors([
        ...resp.data.sort((a, b) => a.name.localeCompare(b.name)),
      ]);
      setRequestors([
        ...resp.data.sort((a, b) => a.name.localeCompare(b.name)),
      ]);
      setRequestorsLoading(false);
    } catch (err) {
      setRequestorsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(err?.response?.data?.error);
      } else {
        setError("Error retrieving requestor information");
      }
    }
  };

  const search = async () => {
    try {
      let masses: Mass[] = [];
      for (let i = 0; i < selectedRequestors.length; i++) {
        const requestor = selectedRequestors[i];
        const resp = await axios.post(`/api/mass/instances/search`, requestor);
        masses = masses.concat(resp.data);
      }
      const myIntentions: IntentionReportEntry[] = masses.map((m: Mass) => {
        return {
          requestor: m.intention?.requestor || "",
          startDateTime: m.startDateTime,
          subject: m.intention?.subject || "",
          price:
            m.intention?.payment === undefined ? "10.00" : m.intention?.payment,
          history: m.intention?.history,
        };
      });
      setIntentions(myIntentions);
      setReport({
        printDateTime: moment().format("dddd, MMM Do YYYY h:mma"),
        total: "" + myIntentions.length * 10,
        intentions: myIntentions || [],
      });
    } catch (err) {
      handleAxiosError(err, "Error retrieving information", setError);
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  useEffect(() => {
    getRequestors();
  }, []);

  return (
    <>
      {requestorsLoading ? (
        <Spinner animation="border" />
      ) : (
        <div>
          <h2>Reports</h2>
          <Card>
            <Card.Body>
              <Card.Title>Intentions by Requestor</Card.Title>
              <hr />
              <Form>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    value={filter}
                    type="text"
                    onChange={handleFilter}
                    placeholder="Filter.."
                    style={{ marginBottom: "10px" }}
                  />
                  <Form.Label>Requestors</Form.Label>
                  <div className="intentions-by-requestor-options-list">
                    {" "}
                    <InfiniteScroll
                      dataLength={requestors?.length || 0}
                      hasMore={false}
                      next={() => {}}
                      loader={<span>Loading...</span>}
                      scrollableTarget="scrollableDiv"
                    >
                      {requestors.map((r) => (
                        <Form.Check
                          type="checkbox"
                          value={JSON.stringify(r)}
                          label={`${r.name} - ${r.phone || "No phone"}`}
                          onChange={handleCheck}
                          checked={
                            !!selectedRequestors.find(
                              (s) => s.name === r.name && s.phone === r.phone
                            )
                          }
                        />
                      ))}
                    </InfiniteScroll>
                  </div>
                </Form.Group>
              </Form>
              <div className="intentions-by-requestor-button-group">
                <Button
                  disabled={selectedRequestors.length === 0}
                  onClick={search}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Search"
                  )}
                </Button>
                <Button
                  style={{
                    visibility: intentions.length > 0 ? "visible" : "hidden",
                  }}
                  onClick={handlePrint}
                >
                  Print
                </Button>
              </div>
              {loaded && (
                <div>
                  <IntentionsReportTable
                    intentions={intentions}
                    error={error}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
          <div style={{ display: "none" }}>
            <IntentionsByRequestorPrintout ref={componentRef} report={report} />
          </div>
        </div>
      )}
    </>
  );
}

export default IntentionsByRequestor;
