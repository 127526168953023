import React from "react";
import "./styles.css";
import { Table, Row, Col, Form } from "react-bootstrap";
import { formatMassTime } from "../../../utils/stringFunctions";
import { ConflictingMass } from "vincent-types/models";
import { UnavailableDatesCart } from "../../../pages/priests/unavailable-dates/new";

interface NewUnavailableDatesPrintoutProps {
  cart: UnavailableDatesCart;
}

export class NewUnavailableDatesPrintout extends React.PureComponent<NewUnavailableDatesPrintoutProps> {
  render() {
    const { cart } = this.props;
    const conflicts = cart.conflicts;
    return (
      <div className="new-unavailable-dates-print-report-container">
        <h2>New Unavailable Dates</h2>
        <hr />
        <Form>
          <Row md={3}>
            <Col>
              <Form.Group controlId="formFirstName">
                <Form.Label>Start Date</Form.Label>
                <Form.Control value={cart.unavailableDates.start} type="text" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formLastName">
                <Form.Label>End Date</Form.Label>
                <Form.Control value={cart.unavailableDates.end} type="text" />
              </Form.Group>
            </Col>
          </Row>
          <Row md={2}>
            <Col>
              <Form.Group controlId="formLastName">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={cart.unavailableDates.notes}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="formIntentions">
            <Form.Label>{`Impacted Intentions (${conflicts.length})`}</Form.Label>
            <div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Intention</th>
                    <th>Requestor</th>
                    <th>{`Current Date & Time`}</th>
                    <th>{`New Date & Time`}</th>
                  </tr>
                </thead>
                <tbody>
                  {conflicts.length > 0 &&
                    conflicts.map((c: ConflictingMass) => (
                      <tr>
                        <td>{c.mass.intention?.subject}</td>
                        <td>{`${c.mass.intention?.requestor} - ${c.mass.intention?.phone}`}</td>
                        <td>{formatMassTime(c.mass)}</td>
                        <td>{formatMassTime(c.suggestedMass)}</td>
                      </tr>
                    ))}
                  {conflicts.length === 0 && (
                    <tr>
                      <td colSpan={4} align="center">
                        No conflicting intentions found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Form.Group>
        </Form>
      </div>
    );
  }
}
