import React from "react";
import "./styles.css";
import { Table } from "react-bootstrap";
import { formatDateTime } from "../../../utils/stringFunctions";

interface IntentionsByPresiderPrintoutProps {
  report: {
    printDateTime?: string;
    presider?: string;
    total: string;
    intentions: {
      subject: string;
      startDateTime: string;
      price: string;
    }[];
  };
}

export class IntentionsByPresiderPrintout extends React.PureComponent<IntentionsByPresiderPrintoutProps> {
  render() {
    const { report } = this.props;
    return (
      <div className="intentions-by-presider-print-report-container">
        <h2>Report Date & Time</h2>
        <h3>{report.printDateTime}</h3>
        <hr />
        <h2>Presider</h2>
        <h3>{report.presider}</h3>
        <hr />
        <h2>Total</h2>
        <h3 className="new-intention-money">
          {Number(
            report.intentions
              .map((i) => Number.parseInt(i.price))
              .reduce((a, b) => a + b, 0)
          )
            .toFixed(2)
            .toLocaleString()}
        </h3>
        <div className="intentions-by-presider-print-table-container">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Intention</th>
                <th>{`Mass Date & Time`}</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {report.intentions.map((i) => (
                <tr>
                  <td>{i.subject}</td>
                  <td>{formatDateTime(i.startDateTime)}</td>
                  <td>{`$${i.price}`}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
