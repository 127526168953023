import React, { useEffect, useState } from "react";
import { Modal, Form, Spinner, Row, Col, Alert } from "react-bootstrap";
import Button from "../../components/permission-based-button";
import { Permissions } from "vincent-types/enums";
import { useDispatch, useSelector } from "react-redux";
import { updateIntentionAsync } from "../../redux/week-slice";
import Calendar from "../../components/calendar";
import moment, { Moment } from "moment";
import {
  Mass,
  AppState,
  Priest,
  Intention,
  Language,
} from "vincent-types/models";
import { BiCheckCircle } from "react-icons/bi";
import MaskedInput from "react-maskedinput";
import { priestIsActive } from "../../utils/priestFunctions";
import CurrencyInput from "react-currency-input-field";
import axios from "axios";
import _ from "lodash";

import "./styles.css";
import { vincentFormat, formatMassTimeOnly } from "../../utils/stringFunctions";

interface EditFormProps {
  mass: Mass;
  onHide?: () => void;
  onMove?: () => void;
}

enum Fields {
  Priest = "Priest",
  Intention = "Intention",
  Deceased = "Deceased",
  Requestor = "Requestor",
  Phone = "Phone",
  Payment = "Payment",
}

function EditForm(props: EditFormProps) {
  const [pending, setPending] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [edited, setEdited] = useState(false);
  const [error, setError] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const priests = useSelector((state: AppState) => state.priests.data);
  const dispatch = useDispatch();
  const [mass, setMass] = useState<Mass>(props.mass);
  const [readOnly, setReadOnly] = useState(false);
  const user = useSelector((state: AppState) => state.user);

  useEffect(() => {
    const noPermissions = ![
      Permissions.EditIntention,
      Permissions.DeleteIntention,
    ].some((p) => user.permissions.includes(p));
    setReadOnly(noPermissions);
  }, [user]);

  const update = (e: any) => {
    const { name, value } = e.target as unknown as HTMLInputElement;
    let newMass = _.cloneDeep(mass) as Mass;
    if (!newMass.intention) {
      newMass.intention = {
        id: 0,
        date: moment(newMass.startDateTime).format("YYYY-MM-DD"),
        massId: newMass.id,
        preferredLanguages: [newMass.language],
        requestor: "",
        subject: "",
        phone: "",
        payment: "10",
        deceased: false,
      };
    }
    switch (name) {
      case Fields.Priest:
        newMass.intention.priest = priests.find(
          (p: Priest) => p.id === Number.parseInt(value)
        );
        break;
      case Fields.Requestor:
        newMass.intention.requestor = value;
        break;
      case Fields.Intention:
        newMass.intention.subject = value;
        if (value.toUpperCase() === "PARISHIONERS") {
          newMass.intention.payment = "0";
        }
        break;
      case Fields.Deceased:
        newMass.intention.deceased = (e.target as HTMLInputElement).checked;
        break;
      case Fields.Phone:
        newMass.intention.phone = value;
        break;
      case Fields.Payment:
        newMass.intention.payment = value;
        break;
    }
    setMass(newMass);
    setEdited(true);
  };

  const canSave = () => {
    if (!edited) {
      return false;
    }
    if (!mass.intention || !mass.intention.subject) {
      return false;
    }
    return true;
  };

  const formatUpdate = (by: string, time: string): string => {
    const formattedTime = moment(time).format("MMM Do @ h:mm a");
    return `${by} on ${formattedTime}`;
  };

  const onDelete = async () => {
    if (mass.intention) {
      try {
        setError("");
        setDeleting(true);
        await axios.delete(`/api/intentions/${mass.intention.id}`);
        props.onHide && props.onHide();
      } catch (err) {
        setError("Error deleting intention");
      } finally {
        setDeleting(false);
      }
    }
  };

  const onSave = async () => {
    if (mass.intention) {
      setError("");
      setPending(true);
      const result: any = await dispatch(updateIntentionAsync(mass.intention));
      setPending(false);
      if (result?.meta?.requestStatus === "fulfilled") {
        props.onHide && props.onHide();
      } else if (result?.meta?.requestStatus === "rejected") {
        setError("Error saving intention");
      }
    }
  };

  return (
    <div className="intention-edit-modal-auto-move-container">
      <div style={{ height: "100%" }}>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Presider</Form.Label>
            <Form.Control
              disabled={readOnly}
              as="select"
              onChange={(e) => update(e)}
              name={Fields.Priest}
              value={Number(mass.intention?.priest?.id)}
            >
              <option value={undefined}>Select</option>
              {priests
                .filter((p) => priestIsActive(p, mass.startDateTime))
                .map((p) => {
                  return (
                    <option
                      value={Number(p.id)}
                    >{`${p.title} ${p.firstName}`}</option>
                  );
                })}
            </Form.Control>
          </Form.Group>
          <Row>
            <Col md="8">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Intention</Form.Label>
                <Form.Control
                  type="text"
                  readOnly={readOnly}
                  name={Fields.Intention}
                  onChange={(e) => update(e)}
                  value={mass.intention?.subject}
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="formDeceased">
                <Form.Label>&nbsp;</Form.Label>
                <Form.Check
                  disabled={readOnly}
                  type="checkbox"
                  label="Deceased"
                  name={Fields.Deceased}
                  onChange={(e) => update(e)}
                  checked={mass.intention?.deceased}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Requestor</Form.Label>
                <Form.Control
                  readOnly={readOnly}
                  type="text"
                  onChange={(e) => update(e)}
                  name={Fields.Requestor}
                  value={mass.intention?.requestor}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  readOnly={readOnly}
                  as={MaskedInput}
                  mask="(111) 111-1111"
                  type="phone"
                  name={Fields.Phone}
                  onChange={(e) => update(e)}
                  value={mass.intention?.phone}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group controlId="Payment">
                <Form.Label>Payment</Form.Label>
                <CurrencyInput
                  readOnly={readOnly}
                  className="form-control"
                  style={{ textAlign: "right" }}
                  id="payment-amount"
                  name={Fields.Payment}
                  placeholder=""
                  defaultValue={""}
                  prefix="$"
                  decimalsLimit={2}
                  value={mass.intention?.payment || ""}
                  onValueChange={(value, name) =>
                    update({
                      target: {
                        value,
                        name,
                      },
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          {mass.intention?.history?.createUsername && (
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Created By</Form.Label>
              <Form.Control
                type="text"
                readOnly
                name={Fields.Phone}
                onChange={(e) => update(e)}
                placeholder={formatUpdate(
                  mass.intention?.history?.createUsername,
                  mass.intention?.history.createDateTime
                )}
              />
            </Form.Group>
          )}
          {mass.intention?.history?.lastUpdateUsername && (
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Last Updated By</Form.Label>
              <Form.Control
                type="text"
                readOnly
                name={Fields.Phone}
                onChange={(e) => update(e)}
                placeholder={formatUpdate(
                  mass.intention?.history.lastUpdateUsername,
                  mass.intention?.history.lastUpdateDateTime || ""
                )}
              />
            </Form.Group>
          )}
        </Form>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        {confirmDelete ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>Are you sure you want to delete?</div>
            <div>
              <Button
                variant="danger"
                className="intention-edit-modal-action-button"
                onClick={onDelete}
              >
                {deleting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Yes"
                )}
              </Button>
              <Button
                variant="primary"
                className="intention-edit-modal-action-button"
                onClick={() => setConfirmDelete(false)}
              >
                No
              </Button>
            </div>
          </div>
        ) : (
          <>
            {mass.intention && (
              <Button
                variant="danger"
                onClick={() => setConfirmDelete(true)}
                className="intention-edit-modal-action-button"
                permissions={[Permissions.DeleteIntention]}
              >
                Delete
              </Button>
            )}
            {mass.intention && (
              <Button
                variant="outline-secondary"
                onClick={props.onMove}
                className="intention-edit-modal-action-button"
                permissions={[Permissions.EditIntention]}
              >
                Move
              </Button>
            )}
            <Button
              variant="primary"
              className="intention-edit-modal-action-button"
              disabled={!canSave()}
              onClick={onSave}
              permissions={[Permissions.EditIntention]}
            >
              {pending ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Save"
              )}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

interface AutoMoveProps {
  intention: Intention;
  currentMassDateTime: string;
  startDateTime: string;
  onNo: () => void;
  onYes: () => void;
}

function AutoMove(props: AutoMoveProps) {
  const { intention, onNo } = props;
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [suggestedMass, setSuggestedMass] = useState<Mass | undefined>();
  useEffect(() => {
    const getSuggestedMass = async () => {
      try {
        const resp = await axios.post("/api/intentions/move", { intention });
        const suggestedMass = resp.data;
        if (!suggestedMass) {
          onNo();
        } else {
          setSuggestedMass(suggestedMass);
        }
      } catch (err) {
        onNo();
      }
    };
    getSuggestedMass();
  }, [intention, onNo]);

  const move = async () => {
    setSaving(true);
    setError("");
    try {
      await axios.put(`/api/intentions`, {
        ...intention,
        date: moment(suggestedMass?.startDateTime).format("YYYY-MM-DD"),
        massId: suggestedMass?.id,
      });
      if (props.onYes) {
        props.onYes();
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError(err?.response?.data?.error);
      } else {
        setError("Error moving intention");
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="intention-edit-modal-auto-move-container">
      {error && <Alert variant="danger">{error}</Alert>}
      <div
        className="intention-edit-modal-auto-move-prompt-container"
        style={{
          marginLeft: "20px",
          marginRight: "20px",
          display: "flex",
          flex: 1,
        }}
      >
        {!suggestedMass && <Spinner animation="border" />}
        {suggestedMass && (
          <span className="intention-edit-modal-auto-move-prompt">
            {`Next available Mass is ${vincentFormat(
              suggestedMass?.startDateTime,
              "dddd, MMM Do YYYY @ h:mm a"
            )}. Would you like to select this Mass?`}
          </span>
        )}
      </div>
      <div
        style={{
          alignSelf: "flex-end",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outline-secondary"
          className="intention-edit-modal-action-button"
          onClick={props.onNo}
        >
          No
        </Button>
        <Button
          variant="primary"
          className="intention-edit-modal-action-button"
          onClick={move}
        >
          {saving ? <Spinner animation="border" size="sm" /> : "Yes"}
        </Button>
      </div>
    </div>
  );
}

interface ManualMoveProps {
  intention: Intention;
  currentMassDateTime: string;
  language: Language;
  onSave: () => void;
  onCancel: () => void;
}

function ManualMove(props: ManualMoveProps) {
  const { intention } = props;
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(
    moment().clone().startOf("month")
  );
  const [unavailableDates, setUnavailableDates] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<undefined | string>(
    undefined
  );
  const [selectedMass, setSelectedMass] = useState<Mass | undefined>(undefined);
  const [masses, setMasses] = useState<{ [key: string]: Mass[] }>({});

  const handleError = (err: unknown, message: string) => {
    if (axios.isAxiosError(err)) {
      setError(err.response?.data.error || message);
    } else {
      setError(message);
    }
  };

  const getInfoForMonth = async (date: string | Moment) => {
    setLoading(true);
    setError("");
    const start = moment(date).clone().startOf("month").format("YYYY-MM-DD");
    const end = moment(date).clone().endOf("month").format("YYYY-MM-DD");
    try {
      const resp = await axios.get(
        `/api/mass/instances?start=${start}&end=${end}`
      );
      const massList = resp.data as Mass[];
      const massesForMonth: { [key: string]: Mass[] } = {};
      for (let i = 0; i < massList.length; i++) {
        const mass = massList[i];
        const massDate = moment(mass.startDateTime).format("YYYY-MM-DD");
        if (!massesForMonth[massDate]) {
          massesForMonth[massDate] = [];
        }
        massesForMonth[massDate].push(mass);
      }
      setMasses(massesForMonth);
      const myDates = Object.keys(massesForMonth).filter((k) => {
        const massesForDay = massesForMonth[k];
        if (massesForDay.length === 0) {
          return false;
        }
        return !massesForDay.find((m) => !m.intention && !m.priestsUnavailable);
      });
      setUnavailableDates(myDates);
    } catch (err) {
      handleError(err, "Error retrieving masses");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getInfoForMonth(moment().format("YYYY-MM-DD"));
  }, []);
  const onBack = () => {
    const newMonth = firstDayOfMonth.clone().subtract(1, "months");
    setSelectedDate(undefined);
    setFirstDayOfMonth(newMonth);
    getInfoForMonth(newMonth);
  };

  const onForward = () => {
    const newMonth = firstDayOfMonth.clone().add(1, "months");
    setSelectedDate(undefined);
    setFirstDayOfMonth(newMonth);
    getInfoForMonth(newMonth);
  };

  const selectDate = (date: string) => {
    setSelectedMass(undefined);
    setSelectedDate(date);
  };

  const selectNewMass = (mass: Mass) => {
    setSelectedMass(mass);
  };

  const save = async () => {
    setSaving(true);
    setError("");
    try {
      const updatedIntention = {
        ...intention,
        massId: selectedMass?.id,
        date: moment(selectedMass?.startDateTime).format("YYYY-MM-DD"),
      };
      await axios.put(`/api/intentions`, updatedIntention);
      setSaving(false);
      if (props.onSave) {
        props.onSave();
      }
    } catch (err) {
      handleError(err, "Error moving intention");
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="intention-edit-modal-auto-move-container">
      {error && <Alert variant="danger">{error}</Alert>}
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Calendar
            date={firstDayOfMonth}
            onSelectDate={selectDate}
            showNavigation={true}
            onBack={onBack}
            onForward={onForward}
            loading={loading}
            unavailableDates={unavailableDates}
          />
          <div
            style={{ marginTop: "10px", display: "flex", flexDirection: "row" }}
          >
            <div
              style={{
                width: "25px",
                height: "25px",
                backgroundColor: "lightgray",
                border: "1px solid",
                marginRight: "10px",
              }}
            ></div>
            No masses available
          </div>
        </div>
        <div
          style={{
            marginLeft: "20px",
            visibility: selectedDate ? "visible" : "hidden",
          }}
        >
          <span style={{ fontSize: "20px" }}>{`Available Masses for ${moment(
            selectedDate
          ).format("dddd, MMM Do YYYY")}:`}</span>
          <div
            style={{ height: "260px", marginTop: "15px", overflowY: "scroll" }}
          >
            {masses &&
              selectedDate &&
              masses[selectedDate] &&
              masses[selectedDate]
                .filter((m: Mass) => !m.intention)
                .filter((m: Mass) => !m.priestsUnavailable)
                .map((m: Mass) => {
                  return (
                    <div
                      className={`intention-edit-modal-mass-option ${
                        m.startDateTime === selectedMass?.startDateTime &&
                        "selected-mass-option"
                      }`}
                      onClick={() => selectNewMass(m)}
                    >
                      {formatMassTimeOnly(m)}
                      {m.startDateTime === selectedMass?.startDateTime && (
                        <BiCheckCircle
                          style={{ color: "green", fontSize: "24px" }}
                        />
                      )}
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      <div
        style={{
          alignSelf: "flex-end",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outline-secondary"
          className="intention-edit-modal-action-button"
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="intention-edit-modal-action-button"
          disabled={!selectedMass || saving}
          onClick={save}
        >
          {saving && <Spinner animation="border" />}
          {!saving && "Save"}
        </Button>
      </div>
    </div>
  );
}

interface IntentionEditModalProps {
  show: boolean;
  mass: Mass;
  onHide: () => void;
  onSave: () => void;
}

function IntentionEditModal(props: IntentionEditModalProps) {
  const { mass } = props;
  const [showAutoMove, setShowAutoMove] = useState(false);
  const [showManualMove, setShowManualMove] = useState(false);

  const chooseManualMove = () => {
    setShowAutoMove(false);
    setShowManualMove(true);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="intention-edit-modal-title"
        >
          {showAutoMove
            ? `Move Intention for ${mass.intention?.subject}`
            : showManualMove
            ? `Choose New Mass for ${mass.intention?.subject}`
            : moment(mass.startDateTime, "YYYY-MM-DD h:mma").format(
                "dddd, MMM YYYY Do h:mma"
              )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showAutoMove && !showManualMove && (
          <EditForm
            mass={mass}
            onHide={props.onHide}
            onMove={() => setShowAutoMove(true)}
          />
        )}
        {showAutoMove && (
          <AutoMove
            onNo={chooseManualMove}
            onYes={props.onHide}
            currentMassDateTime={mass.startDateTime}
            intention={mass.intention as Intention}
            startDateTime={mass.startDateTime}
          />
        )}
        {showManualMove && (
          <ManualMove
            intention={mass.intention as Intention}
            currentMassDateTime={mass.startDateTime}
            language={mass.language}
            onCancel={props.onHide}
            onSave={props.onSave}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default IntentionEditModal;
