import { useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { formatDateTime } from "../../utils/stringFunctions";
import { CgCross } from "react-icons/cg";
import RequestorDetails from "./requestor-details";
import PaymentDetails from "./payment-details";
import IntentionDetails from "./intention-details";
import Summary from "./summary";
import {
  IntentionCart,
  Requestor,
  RequestedIntention,
} from "vincent-types/models";
import "./styles.css";

interface IntentionsTableProps {
  intentions: RequestedIntention[];
  height?: string;
}

export const IntentionsTable = (props: IntentionsTableProps) => {
  return (
    <div
      className="new-intention-table-container"
      style={{ maxHeight: props.height || "400px" }}
    >
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Intention</th>
            <th>{`Mass Date & Time`}</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {props.intentions.map((i: RequestedIntention) => (
            <tr>
              <td>
                {i.subject}
                {i.deceased && (
                  <CgCross size="18px" style={{ marginTop: "-5px" }} />
                )}
              </td>
              <td>{formatDateTime(i.dateTime)}</td>
              <td>{`$${i.price}`}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

function NewIntentionPage() {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [cart, setCart] = useState<IntentionCart>({
    requestor: {
      name: "",
      phone: "",
      languages: [],
    },
    intentions: [],
    payment: "",
  });
  const saveRequestor = (requestor: Requestor) => {
    const updatedRequest = {
      ...cart,
      requestor,
    };
    setCart(updatedRequest);
    setStep(1);
  };
  const saveIntentions = (intentions: RequestedIntention[]) => {
    const updatedRequest = {
      ...cart,
      intentions,
    };
    setCart(updatedRequest);
    setStep(2);
  };

  const savePayment = (payment: string) => {
    const updatedRequest = {
      ...cart,
      payment,
    };
    setCart(updatedRequest);
    setStep(3);
  };

  // const onSave = () => {
  //   console.log("Saving!");
  // };
  const onCancel = () => {
    history.push("/");
  };
  return (
    <div>
      <h3>New Intention</h3>
      {step === 0 && (
        <RequestorDetails
          cart={cart}
          onCancel={onCancel}
          onNext={saveRequestor}
        />
      )}
      {step === 1 && (
        <IntentionDetails
          languages={cart.requestor.languages}
          intentions={cart.intentions}
          onCancel={onCancel}
          onNext={saveIntentions}
          onBack={() => setStep(0)}
        />
      )}
      {step === 2 && (
        <PaymentDetails
          cart={cart}
          onCancel={onCancel}
          onNext={savePayment}
          onBack={() => setStep(1)}
        />
      )}
      {step === 3 && (
        <Summary cart={cart} onCancel={onCancel} onBack={() => setStep(2)} />
      )}
    </div>
  );
}

export default NewIntentionPage;
