import { useEffect } from "react";
import ActionBar from "../../../components/action-bar";
import { getUnavailableDatesAsync } from "../../../redux/unavailable-date-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { AppState } from "vincent-types/models";
import { Permissions } from "vincent-types/enums";
import { Card, Table } from "react-bootstrap";
import moment from "moment";

function UnavailableDatesPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const unavailableDates = useSelector(
    (state: AppState) => state.unavailableDates.data
  );
  const error = useSelector((state: AppState) => state.unavailableDates.error);
  useEffect(() => {
    dispatch(getUnavailableDatesAsync());
  }, [dispatch]);

  const handleClick = (id: number) => {
    history.push(`${location.pathname}/${id}`);
  };

  return (
    <div>
      <ActionBar
        title="Priests"
        to="/unavailable-dates/new"
        buttonTitle="New"
        buttonPermissions={[Permissions.EditPriestUnavailableDates]}
      />
      <Card>
        <Card.Body className="new-intention-card">
          <Card.Title>Unavailable Dates</Card.Title>
          <hr />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {unavailableDates &&
                unavailableDates.map((u) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClick(u.id)}
                  >
                    <td>{`${moment(u.startDate).format(
                      "dddd, MMM Do YYYY"
                    )}`}</td>
                    <td>{`${moment(u.endDate).format(
                      "dddd, MMM Do YYYY"
                    )}`}</td>
                    <td>{u.notes}</td>
                  </tr>
                ))}
              {!error &&
                (!unavailableDates || unavailableDates.length === 0) && (
                  <tr>
                    <td colSpan={3} align="center">
                      No unavailable dates found
                    </td>
                  </tr>
                )}
              {error && (
                <tr>
                  <td colSpan={3} align="center">
                    {error}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}

export default UnavailableDatesPage;
