import {
  Card,
  Row,
  Col,
  Form,
  Table,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import { UnavailableDatesCart } from "../index";
import { useState, useRef } from "react";
import { ConflictingMass } from "vincent-types/models";
import moment from "moment";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { formatMassTime } from "../../../../../utils/stringFunctions";
import { NewUnavailableDatesPrintout } from "../../../../../components/print-components/new-unavailable-dates";
import { handleAxiosError } from "../../../../../utils/network";

interface NewUnavailableDatesSummaryProps {
  cart: UnavailableDatesCart;
  onCancel: () => void;
  onBack: () => void;
}

function NewUnavailableDatesSummary(props: NewUnavailableDatesSummaryProps) {
  const { cart } = props;
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const componentRef = useRef<any>();
  const conflicts = cart.conflicts;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const save = async () => {
    try {
      setError("");
      setSaving(true);
      setSuccess(false);
      const payload = {
        unavailableDate: {
          startDate: cart.unavailableDates.start,
          endDate: cart.unavailableDates.end,
          notes: cart.unavailableDates.notes,
        },
        intentions: cart.conflicts.map((c) => {
          return {
            ...c.mass.intention,
            date: moment(c.suggestedMass.startDateTime).format("YYYY-MM-DD"),
            massId: c.suggestedMass.id,
          };
        }),
      };
      await axios.post("/api/priests/unavailable-dates", payload);
      setSuccess(true);
    } catch (err) {
      handleAxiosError(err, "Error saving unavailable dates", setError);
    } finally {
      setSaving(false);
    }
  };
  return (
    <Card>
      <Card.Body className="unavailable-dates-card">
        {error && <Alert variant="danger">{error}</Alert>}
        <Card.Title>Summary</Card.Title>
        <hr />
        <Form>
          <Row md={3}>
            <Col>
              <Form.Group controlId="formFirstName">
                <Form.Label>Start Date</Form.Label>
                <Form.Control value={cart.unavailableDates.start} type="text" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formLastName">
                <Form.Label>End Date</Form.Label>
                <Form.Control value={cart.unavailableDates.end} type="text" />
              </Form.Group>
            </Col>
          </Row>
          <Row md={2}>
            <Col>
              <Form.Group controlId="formLastName">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={cart.unavailableDates.notes}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="formIntentions">
            <Form.Label>{`Impacted Intentions (${conflicts.length})`}</Form.Label>
            <div
              className="new-intention-table-container"
              style={{ maxHeight: "400px" }}
            >
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Intention</th>
                    <th>Requestor</th>
                    <th>{`Current Date & Time`}</th>
                    <th>{`New Date & Time`}</th>
                  </tr>
                </thead>
                <tbody>
                  {conflicts.length > 0 &&
                    conflicts.map((c: ConflictingMass) => (
                      <tr>
                        <td>{c.mass.intention?.subject}</td>
                        <td>{`${c.mass.intention?.requestor} - ${c.mass.intention?.phone}`}</td>
                        <td>{formatMassTime(c.mass)}</td>
                        <td>{formatMassTime(c.suggestedMass)}</td>
                      </tr>
                    ))}
                  {conflicts.length === 0 && (
                    <tr>
                      <td colSpan={4} align="center">
                        No conflicting intentions found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Form.Group>
        </Form>

        {!success && (
          <div className="new-intention-button-group">
            <Button variant="outline-secondary" onClick={props.onCancel}>
              Cancel
            </Button>
            <div className="new-intention-button-spacer" />
            <Button variant="outline-primary" onClick={props.onBack}>
              Back
            </Button>
            <div className="new-intention-button-spacer" />
            <Button variant="success" onClick={save}>
              {saving ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        )}

        {success && (
          <div className="new-intention-button-group">
            <Button variant="outline-primary" onClick={props.onCancel}>
              Close
            </Button>
            <div className="new-intention-button-spacer" />
            <Button variant="success" onClick={handlePrint}>
              Print
            </Button>
          </div>
        )}
      </Card.Body>
      <div style={{ display: "none" }}>
        <NewUnavailableDatesPrintout ref={componentRef} cart={cart} />
      </div>
    </Card>
  );
}

export default NewUnavailableDatesSummary;
