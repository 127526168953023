import { Mass } from "vincent-types/models";
import moment, { Moment } from "moment";

export const formatMassTime = (m: Mass) => {
  return `${moment(m.startDateTime, "YYYY-MM-DD h:mma").format(
    "dddd, MMM Do YYYY h:mma"
  )} - ${m.language.name}`;
};
export const formatMassTimeOnly = (m: Mass) => {
  return `${moment(m.startDateTime, "YYYY-MM-DD h:mma").format(
    "h:mma"
  )} - ${capitalizeFirstLetter(m.language.name)}`;
};

export const formatDateTime = (dt: string | Moment) => {
  return `${moment(dt, "YYYY-MM-DD h:mma").format("dddd, MMM Do YYYY h:mma")}`;
};

export const formatOnlyDate = (dt: string | Moment) => {
  return `${moment(dt).format("dddd, MMM Do YYYY")}`;
};

export const formatDate = (dt: string | Moment) => {
  return `${moment.utc(dt).local().format("dddd, MMM Do YYYY h:mma")}`;
};

export const formatHistory = (
  history: { createDateTime: string; createUsername: string } | undefined
) => {
  if (!history) {
    return "";
  }
  return `${formatDate(history.createDateTime)} / ${history.createUsername}`;
};

export const vincentFormat = (dt: string, format: string) => {
  return `${moment(dt, "YYYY-MM-DD h:mma").format(format)}`;
};

export const capitalizeFirstLetter = (text: string) => {
  if (!text) {
    return "";
  }
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
