import { useState, useEffect } from "react";
import { Card, Form, Row, Col, Spinner } from "react-bootstrap";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState, RecurringMassTemplate } from "vincent-types/models";
import { Permissions } from "vincent-types/enums";
import Button from "../../../../components/permission-based-button";
import moment from "moment";
import "./styles.css";
import _ from "lodash";
import axios from "axios";
import ErrorModal from "../../../../modals/error-modal";
import { handleAxiosError } from "../../../../utils/network";

enum Controls {
  Frequency = "Frequency",
  Weekday = "Weekday",
  Week = "Week",
  StartTime = "StartTime",
  EffectiveDate = "EffectiveDate",
  StartTimeEffectiveDate = "StartTimeEffectiveDate",
  ExceptionDate = "ExceptionDate",
  Language = "Language",
}

enum FrequencyOptions {
  Weekly = 0,
  Monthly = 1,
}

const Languages = [
  {
    name: "Select",
    value: -1,
  },
  {
    name: "English",
    value: 1,
  },
  {
    name: "Spanish",
    value: 2,
  },
  {
    name: "Bilingual",
    value: 3,
  },
  {
    name: "Vietnamese",
    value: 4,
  },
];

const Weekdays = [
  {
    name: "Select",
    value: -1,
  },
  {
    name: "Sunday",
    value: 0,
  },
  {
    name: "Monday",
    value: 1,
  },
  {
    name: "Tuesday",
    value: 2,
  },
  {
    name: "Wednesday",
    value: 3,
  },
  {
    name: "Thursday",
    value: 4,
  },
  {
    name: "Friday",
    value: 5,
  },
  {
    name: "Saturday",
    value: 6,
  },
];

const Frequencies = [
  {
    name: "Select",
    value: "-1",
  },
  {
    name: "Weekly",
    value: FrequencyOptions.Weekly,
  },
  {
    name: "Monthly",
    value: FrequencyOptions.Monthly,
  },
];

const Weeks = [
  {
    name: "Select",
    value: -1,
  },
  {
    name: "First",
    value: 1,
  },
  {
    name: "Second",
    value: 2,
  },
  {
    name: "Third",
    value: 3,
  },
  {
    name: "Fourth",
    value: 4,
  },
  {
    name: "Fifth",
    value: 5,
  },
];

interface MassStartTime {
  start: string;
  effectiveDate: string;
}

const canRemoveStartTime = (massStartTime: MassStartTime): boolean => {
  const tempDate = moment(massStartTime.effectiveDate);
  const today = moment();
  return today.diff(tempDate, "days") < 0;
};

const INITIAL_STATE: RecurringMassTemplate = {
  id: 0,
  effectiveDate: "",
  day: {
    number: -1,
    name: "",
  },
  times: [
    {
      start: "",
      effectiveDate: "",
    },
  ],
  language: {
    id: -1,
    name: "",
  },
  exceptions: [""],
  recurrence: {
    week: -1,
    description: "",
  },
};

function NewRecurringMass() {
  const params = useParams<{ id: string | undefined }>();
  const history = useHistory();
  const mass = useSelector((state: AppState) =>
    state.masses.recurring.data.find(
      (m) => m.id === Number.parseInt(params.id || "-1")
    )
  );
  const cloneMass = (mass: RecurringMassTemplate): RecurringMassTemplate => {
    const clonedMass = _.cloneDeep(mass);
    if (!clonedMass.exceptions || clonedMass.exceptions.length === 0) {
      clonedMass.exceptions = [];
      clonedMass.exceptions.push("");
    }
    return clonedMass;
  };
  const [newMass, setNewMass] = useState(
    mass ? cloneMass(mass) : _.cloneDeep(INITIAL_STATE)
  );
  const [selectedFrequency, setSelectedFrequency] = useState(
    newMass.recurrence.week === 0
      ? FrequencyOptions.Weekly
      : newMass.recurrence.week > 0
      ? FrequencyOptions.Monthly
      : -1
  );
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setNewMass(mass ? cloneMass(mass) : _.cloneDeep(INITIAL_STATE));
  }, [mass]);

  const save = async () => {
    try {
      setSaving(true);
      setError("");
      if (!newMass.effectiveDate) {
        newMass.effectiveDate = newMass.times[0].effectiveDate;
      }
      newMass.exceptions = newMass.exceptions.filter((e) => e !== "");
      await axios.post("/api/mass/templates/recurring", newMass);
      history.goBack();
    } catch (err) {
      handleAxiosError(err, "Error saving mass", setError);
    } finally {
      setSaving(false);
    }
  };

  // const deleteMass = async () => {
  //   try {
  //     setDeleting(true);
  //     setError("");
  //     await axios.delete(`/api/mass/templates/recurring/${newMass.id}`);
  //     history.goBack();
  //   } catch (err) {
  //     setError(err?.response?.data?.error || "Error deleting intention");
  //   } finally {
  //     setDeleting(false);
  //   }
  // };

  const canSave = (): boolean => {
    return (
      newMass &&
      newMass.day.number !== -1 &&
      newMass.recurrence.week !== -1 &&
      newMass.times.some((t) => t.start !== "" && t.effectiveDate !== "") &&
      newMass.language.id !== -1
    );
  };

  const onSelectDropdownValue = (event: any, index?: number) => {
    let updatedMass = _.cloneDeep(newMass);
    switch (event.target.name) {
      case Controls.Frequency:
        setSelectedFrequency(event.target.value);
        if (event.target.value === FrequencyOptions.Weekly.valueOf()) {
          updatedMass.recurrence.week = event.target.value;
        } else {
          updatedMass.recurrence.week = -1;
        }
        setNewMass(updatedMass);
        // setSelectedDay(-1);
        // setSelectedWeek(-1);
        break;
      case Controls.StartTime:
        updatedMass.times[index as number].start = event.target.value;
        setNewMass(updatedMass);
        break;
      case Controls.StartTimeEffectiveDate:
        updatedMass.times[index as number].effectiveDate = event.target.value;
        setNewMass(updatedMass);
        break;
      case Controls.ExceptionDate:
        updatedMass.exceptions[index as number] = event.target.value;
        setNewMass(updatedMass);
        break;
      case Controls.Language:
        updatedMass.language.id = event.target.value;
        setNewMass(updatedMass);
        break;
      case Controls.Week:
        updatedMass.recurrence.week = event.target.value;
        setNewMass(updatedMass);
        break;
      case Controls.Weekday:
        updatedMass.day.number = event.target.value;
        if (selectedFrequency === FrequencyOptions.Weekly) {
          updatedMass.recurrence.week = 0;
        }
        setNewMass(updatedMass);
        break;
    }
  };

  const removeExceptionDate = (index: number) => {
    let updatedMass = _.cloneDeep(newMass);
    updatedMass.exceptions.splice(index, 1);
    setNewMass(updatedMass);
  };

  const removeStartTime = (index: number) => {
    let updatedMass = _.cloneDeep(newMass);
    updatedMass.times.splice(index, 1);
    setNewMass(updatedMass);
  };

  const addExceptionDate = () => {
    let updatedMass = _.cloneDeep(newMass);
    updatedMass.exceptions.push("");
    setNewMass(updatedMass);
  };

  const addStartTime = () => {
    let updatedMass = _.cloneDeep(newMass);
    updatedMass.times.push({ start: "", effectiveDate: "" });
    setNewMass(updatedMass);
  };

  return (
    <div>
      <h2>{`${
        params.id && params.id !== "0" ? "Edit" : "New"
      } Recurring Mass`}</h2>

      <Card>
        <Card.Body className="new-intention-card">
          <Card.Title>Day</Card.Title>
          <hr />
          <Form.Group controlId="frequency">
            <Form.Label>Frequency</Form.Label>
            <Form.Control
              value={selectedFrequency}
              onChange={onSelectDropdownValue}
              as="select"
              size="lg"
              style={{ maxWidth: "400px" }}
              name={Controls.Frequency}
            >
              {Frequencies.map((f) => (
                <option value={f.value}>{f.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          {selectedFrequency !== -1 && (
            <>
              {selectedFrequency === FrequencyOptions.Weekly && (
                <Form.Group>
                  <Form.Label>Every</Form.Label>
                  <Form.Control
                    value={newMass.day.number}
                    onChange={onSelectDropdownValue}
                    as="select"
                    size="lg"
                    style={{ maxWidth: "400px" }}
                    name={Controls.Weekday}
                  >
                    {Weekdays.map((f) => (
                      <option value={f.value}>{f.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
              {selectedFrequency === FrequencyOptions.Monthly && (
                <Form.Group>
                  <Form.Label>Every</Form.Label>
                  <Row style={{ maxWidth: "800px" }}>
                    <Col>
                      <Form.Control
                        value={newMass.recurrence.week}
                        onChange={onSelectDropdownValue}
                        as="select"
                        size="lg"
                        name={Controls.Week}
                      >
                        {Weeks.map((f) => (
                          <option value={f.value}>{f.name}</option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Control
                        value={newMass.day.number}
                        onChange={onSelectDropdownValue}
                        as="select"
                        size="lg"
                        name={Controls.Weekday}
                      >
                        {Weekdays.map((f) => (
                          <option value={f.value}>{f.name}</option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
              )}
            </>
          )}
          {newMass.id > 0 && (
            <>
              <br />
              <Card.Title>Effective Start & End Date</Card.Title>
              <hr />
              <Form.Group controlId="formFirstName">
                <Row md="4">
                  <Col>
                    <Form.Label>Start</Form.Label>
                    <Form.Control
                      type="date"
                      disabled={true}
                      name={Controls.StartTimeEffectiveDate}
                      value={newMass.effectiveDate}
                      onChange={(e) => onSelectDropdownValue(e)}
                    />
                  </Col>
                  <Col>
                    <Form.Label>End</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={true}
                      name={Controls.StartTimeEffectiveDate}
                      value={newMass.endDate}
                      onChange={(e) => onSelectDropdownValue(e)}
                    />
                  </Col>
                  <Col md="3">
                    <Form.Label>&nbsp;</Form.Label>
                    <Button className="form-control" disabled>
                      Set End Date...
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </>
          )}
          <br />
          <Card.Title>Time</Card.Title>
          <hr />
          <Form.Group controlId="formFirstName">
            <Row md="4">
              <Col>
                <Form.Label>Start Time</Form.Label>
              </Col>
              <Col>
                <Form.Label>Effective</Form.Label>
              </Col>
            </Row>
            {newMass.times.map((s, i) => {
              return (
                <Row md="4" className="new-recurring-mass-start-time-row">
                  <Col>
                    <Form.Control
                      type="time"
                      name={Controls.StartTime}
                      value={s.start}
                      onChange={(e) => onSelectDropdownValue(e, i)}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="date"
                      name={Controls.StartTimeEffectiveDate}
                      value={s.effectiveDate}
                      onChange={(e) => onSelectDropdownValue(e, i)}
                    />
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      paddingLeft: "0px",
                    }}
                  >
                    {canRemoveStartTime(s) && (
                      <IoRemoveCircleOutline
                        className="actions"
                        size="28px"
                        onClick={() => removeStartTime(i)}
                      />
                    )}
                    {i === newMass.times.length - 1 &&
                      s.effectiveDate &&
                      s.start && (
                        <IoAddCircleOutline
                          className="actions"
                          size="28px"
                          onClick={() => addStartTime()}
                        />
                      )}
                  </Col>
                </Row>
              );
            })}
          </Form.Group>
          <Card.Title>Language</Card.Title>
          <hr />
          <Form.Group controlId="frequency">
            <Form.Label>Language</Form.Label>
            <Form.Control
              value={newMass.language.id}
              onChange={onSelectDropdownValue}
              as="select"
              size="lg"
              style={{ maxWidth: "400px" }}
              name={Controls.Language}
            >
              {Languages.map((f) => (
                <option value={f.value}>{f.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <br />
          <Card.Title>Exception Dates</Card.Title>
          <hr />
          <Form.Group controlId="exceptionDate">
            <Form.Label>Exception Date</Form.Label>
            {newMass.exceptions.map((exceptionDate, i) => {
              return (
                <Row md="4" className="new-recurring-mass-start-time-row">
                  <Col>
                    <Form.Control
                      type="date"
                      name={Controls.ExceptionDate}
                      value={exceptionDate}
                      onChange={(e) => onSelectDropdownValue(e, i)}
                    />
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      paddingLeft: "0px",
                    }}
                  >
                    {
                      <IoRemoveCircleOutline
                        className="actions"
                        size="28px"
                        onClick={() => removeExceptionDate(i)}
                      />
                    }
                    {i === newMass.exceptions.length - 1 && exceptionDate && (
                      <IoAddCircleOutline
                        className="actions"
                        size="28px"
                        onClick={() => addExceptionDate()}
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
          </Form.Group>
          <div className="new-recurring-mass-button-group">
            <Button
              variant="outline-secondary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
            {/* <Button variant="outline-danger" onClick={deleteMass}>
              {deleting ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Delete"
              )}
            </Button> */}
            <Button
              className="edit-recurring-mass-button"
              permissions={[
                Permissions.EditRecurringMass,
                Permissions.CreateRecurringMass,
              ]}
              variant="success"
              onClick={save}
              disabled={!canSave()}
            >
              {saving ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Card.Body>
      </Card>
      <ErrorModal error={error} onHide={() => setError("")} />
    </div>
  );
}

export default NewRecurringMass;
