import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PriestState, Priest } from "vincent-types/models";
import moment from "moment";
import axios from "axios";

const INITIAL_STATE: PriestState = {
  data: [],
};

export const getPriestsAsync = createAsyncThunk(
  "priests/getPriestsAsync",
  async () => {
    const resp = await axios.get("/api/priests");
    return resp.data;
  }
);

export const priestSlice = createSlice({
  name: "masses",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getPriestsAsync.fulfilled.toString()]: (state, action) => {
      state.data = action.payload as Priest[];
      state.refreshed = moment().toLocaleString();
    },
    [getPriestsAsync.rejected.toString()]: (state, action) => {
      state.error = action.payload?.err?.message || "Error retrieving priests";
    },
    [getPriestsAsync.pending.toString()]: (state, action) => {
      state.refreshed = undefined;
      state.error = undefined;
      state.data = [];
    },
  },
});

// export const { logout, setPreference } = massSlice.actions;

export default priestSlice.reducer;
