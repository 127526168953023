import { useEffect } from "react";
import ActionBar from "../../../components/action-bar";
import { getSpecialMassesAsync } from "../../../redux/mass-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppState } from "vincent-types/models";
import { Card, Table } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { Permissions } from "vincent-types/enums";

function SpecialMassesPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const masses = useSelector((state: AppState) => state.masses.special.data);
  const error = useSelector((state: AppState) => state.masses.special.error);
  useEffect(() => {
    dispatch(getSpecialMassesAsync());
  }, [dispatch]);
  return (
    <div>
      <ActionBar
        title="Masses"
        to="/special-masses/new"
        buttonTitle="New"
        buttonPermissions={[Permissions.CreateSpecialMass]}
      />
      <Card>
        <Card.Body className="new-intention-card">
          <Card.Title>Special</Card.Title>
          <hr />
          <div
            className="new-intention-table-container"
            style={{ maxHeight: "400px" }}
          >
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Mass Date</th>
                  <th>Mass Time</th>
                  <th>Language</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {!error &&
                  masses &&
                  [...masses]
                    .sort((a, b) =>
                      a.startDateTime < b.startDateTime ? -1 : 1
                    )
                    .map((m) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(location.pathname + "/" + m.id)
                        }
                      >
                        <td>
                          {moment(m.startDateTime).format("dddd, MMM Do YYYY")}
                        </td>
                        <td>{moment(m.startDateTime).format("h:mm a")}</td>
                        <td>{m.language.name}</td>
                        <td>{m.notes}</td>
                      </tr>
                    ))}
                {error && (
                  <tr>
                    <td colSpan={4} align="center">
                      {error}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default SpecialMassesPage;
