import { useState, useRef } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { IntentionReportEntry, Mass } from "vincent-types/models";
import moment from "moment";
import "./styles.css";
import axios from "axios";
import { IntentionsReportTable } from "../../../components/intention-report";
import {
  IntentionsByRequestDateData,
  IntentionsByRequestDatePrintout,
} from "../../../components/print-components/intentions-by-request-date";

function IntentionsByRequestDate() {
  const [intentions, setIntentions] = useState<IntentionReportEntry[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [report, setReport] = useState<IntentionsByRequestDateData>({
    requestStartDate: "",
    requestEndDate: "",
    intentions: [],
  });
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const search = async () => {
    setIntentions([]);
    setLoading(true);
    setLoaded(false);
    setError("");
    try {
      const resp = await axios.post(`/api/mass/instances/search`, {
        requestStartDate: startDate,
        requestEndDate: endDate,
      });
      const masses = resp.data as Mass[];
      const myIntentions: IntentionReportEntry[] = masses.map((m: Mass) => {
        return {
          requestor: m.intention?.requestor || "",
          startDateTime: m.startDateTime,
          subject: m.intention?.subject || "",
          price:
            m.intention?.payment === undefined ? "10.00" : m.intention?.payment,
          history: m.intention?.history,
        };
      });
      setIntentions(myIntentions);
      setReport({
        printDateTime: moment().format("dddd, MMM Do YYYY h:mma"),
        requestStartDate: startDate,
        requestEndDate: endDate,
        intentions: myIntentions || [],
      });
    } catch (err: any) {
      setError(err?.response?.data?.error || "Error retrieving information");
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  return (
    <div>
      <h2>Reports</h2>
      <Card>
        <Card.Body className="new-intention-card">
          <Card.Title>Intentions By Request Date</Card.Title>
          <hr />
          <Form>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    value={startDate}
                    type="date"
                    onChange={(event) => {
                      setStartDate(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formLastName">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    value={endDate}
                    type="date"
                    onChange={(event) => {
                      setEndDate(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="intentions-by-presider-button-group">
            <Button disabled={!startDate || !endDate} onClick={search}>
              {loading ? <Spinner animation="border" size="sm" /> : "Search"}
            </Button>
            <Button
              style={{
                visibility: intentions.length > 0 ? "visible" : "hidden",
              }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </div>
          {loaded && (
            <div>
              <IntentionsReportTable intentions={intentions} error={error} />
            </div>
          )}
        </Card.Body>
      </Card>
      <div style={{ display: "none" }}>
        <IntentionsByRequestDatePrintout ref={componentRef} report={report} />
      </div>
    </div>
  );
}

export default IntentionsByRequestDate;
