import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Card, Form, Row, Col, Spinner, Alert } from "react-bootstrap";
import { AppState } from "vincent-types/models";
import Button from "../../../../components/permission-based-button";
import axios from "axios";
import { Permissions } from "vincent-types/enums";

function EditUnavailableDates() {
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState("");
  const params = useParams<{ id?: string | undefined }>();
  const unavailDates = useSelector((state: AppState) =>
    state.unavailableDates.data.find(
      (d) => d.id === Number.parseInt(params.id || "0")
    )
  );
  const history = useHistory();

  const deleteUnavailDates = async () => {
    setDeleting(true);
    setError("");
    try {
      await axios.delete(`/api/priests/unavailable-dates/${unavailDates?.id}`);
      history.push("/unavailable-dates");
    } catch (err) {
      setError(
        err?.response?.data?.error || "Error deleting unavailable dates"
      );
    } finally {
      setDeleting(false);
    }
  };
  return (
    <div>
      <h2>{`Edit Unavailable Dates`}</h2>
      <Card>
        <Card.Body className="new-intention-card">
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Row md={3}>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    value={unavailDates?.startDate}
                    type="text"
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formLastName">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    value={unavailDates?.endDate}
                    type="text"
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row md={2}>
              <Col>
                <Form.Group controlId="formLastName">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    readOnly={true}
                    value={unavailDates?.notes}
                    type="text"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="new-recurring-mass-button-group">
            <Button
              variant="outline-secondary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={deleteUnavailDates}
              permissions={[Permissions.EditPriestUnavailableDates]}
            >
              {deleting ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Delete"
              )}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default EditUnavailableDates;
