import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppState } from "vincent-types/models";
import "./styles.css";

interface ActionBarProps {
  title: string;
  to: string;
  buttonTitle: string;
  buttonPermissions?: string[];
}

function ActionBar(props: ActionBarProps) {
  const { buttonPermissions } = props;
  const user = useSelector((state: AppState) => state.user);
  const showButton = () => {
    if (!buttonPermissions) {
      return true;
    }
    return buttonPermissions.some((b) => user.permissions.includes(b));
  };
  return (
    <div className="action-bar-container">
      <h3>{props.title}</h3>
      <div>
        {showButton() && (
          <Button as={Link} to={props.to} className="btn btn-success">
            {props.buttonTitle}
          </Button>
        )}
      </div>
    </div>
  );
}

export default ActionBar;
