import {
  BsCalendar,
  BsFillCaretLeftFill,
  BsFillCaretRightFill,
} from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { BiRefresh } from "react-icons/bi";
import moment from "moment";
import { selectDate } from "../../redux/week-slice";
import "./styles.css";
import { AppState } from "vincent-types/models";

interface WeekHeaderProps {
  onChangeDate?: (selectedDate: string) => void;
  onCalendarClick?: () => void;
  onRefresh: () => void;
}

function WeekHeader(props: WeekHeaderProps) {
  const dates = useSelector((state: AppState) => state.week.dates);
  const lastRefreshTs = useSelector((state: AppState) => state.week.refreshed);
  const dispatch = useDispatch();

  const onClickBack = () => {
    if (dates) {
      const nextWeek = moment(dates.start).subtract(7, "days");
      dispatch(selectDate(nextWeek));
      if (props.onChangeDate) {
        props.onChangeDate(nextWeek.format("YYYY-MM-DD"));
      }
    }
  };

  const onClickNext = () => {
    if (dates) {
      const previousWeek = moment(dates.start).add(7, "days");
      dispatch(selectDate(previousWeek));
      if (props.onChangeDate) {
        props.onChangeDate(previousWeek.format("YYYY-MM-DD"));
      }
    }
  };

  const onCalendarClick = () => {
    if (props.onCalendarClick) {
      props.onCalendarClick();
    }
  };

  return (
    <div
      style={{
        paddingBottom: "5px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div className="week-header-last-refresh">
        <div>
          <BiRefresh
            style={{ fontSize: "24px", marginRight: "10px", cursor: "pointer" }}
            onClick={props.onRefresh}
          />
          Last updated: {moment(lastRefreshTs).format("MMM DD @ h:mm:ss a")}
        </div>
      </div>
      {dates && (
        <div className="week-header-control">
          <h3>
            <BsFillCaretLeftFill
              onClick={onClickBack}
              style={{ marginTop: "-5px", cursor: "pointer" }}
            />
          </h3>
          <h3>
            {moment(dates.start).format("MMM Do")} -{" "}
            {moment(dates.end).format("MMM Do")}{" "}
            <BsCalendar
              onClick={onCalendarClick}
              style={{ marginTop: "-5px", cursor: "pointer" }}
            />
          </h3>
          <h3>
            <BsFillCaretRightFill
              onClick={onClickNext}
              style={{ marginTop: "-5px", cursor: "pointer" }}
            />
          </h3>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* <BiGridHorizontal
          size="36px"
          onClick={() => onLayoutSelect(HomeLayoutOptions.Landscape)}
          style={{
            cursor: "pointer",
            border: isLandscapeSelected() ? "1px solid" : undefined,
          }}
        />
        <BiGridVertical
          size="36px"
          onClick={() => onLayoutSelect(HomeLayoutOptions.Portrait)}
          style={{
            cursor: "pointer",
            border: isPortraitSelected() ? "1px solid" : undefined,
          }}
        /> */}
      </div>
    </div>
  );
}

export default WeekHeader;
