import NavBar from "./components/navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import LoginPage from "./pages/login";
import RecurringMassesPage from "./pages/masses/recurring";
import SpecialMassesPage from "./pages/masses/special";
import PriestsPage from "./pages/priests/roster";
import UnavailableDatesPage from "./pages/priests/unavailable-dates";
import NewIntentionPage from "./pages/new-intention";
import HomePage from "./pages/home";
import { logout } from "./redux/user-slice";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./theme-dark-blue.css";
import "./App.css";
import BulletinPrintout from "./pages/reports/bulletin-printout";
import IntentionsByPresider from "./pages/reports/intentions-by-presider";
import IntentionsWithoutPresider from "./pages/reports/intentions-without-presider";
import { AppState } from "./common.save/models";
import { LoginStatuses } from "vincent-types/enums";
import NewRecurringMass from "./pages/masses/recurring/edit";
import NewUnavailableDatesPage from "./pages/priests/unavailable-dates/new";
import EditUnavailableDatesPage from "./pages/priests/unavailable-dates/edit";
import EditSpecialMassPage from "./pages/masses/special/edit";
import EditPriestPage from "./pages/priests/roster/edit";
import UsersPage from "./pages/admin/users";
import EditUserPage from "./pages/admin/users/edit";
import ActivityPage from "./pages/admin/activity";
import IntentionsByRequestor from "./pages/reports/intentions-by-requestor";

import "react-bootstrap-typeahead/css/Typeahead.css";
import IntentionsByRequestDate from "./pages/reports/intentions-by-request-date";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(
    (state: AppState) =>
      state.user.login.status === LoginStatuses.LoggedIn.valueOf() &&
      state.user.permissions &&
      state.admin.permissions
  );
  const logoutClick = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (!loggedIn) {
      dispatch(logout());
    }
  }, [loggedIn, dispatch]);

  return (
    <div className="App">
      {!loggedIn ? (
        <LoginPage />
      ) : (
        <>
          <Router>
            <NavBar logout={logoutClick} />
            <div className="app-container">
              <Switch>
                <Route path="/login">
                  <LoginPage />
                </Route>
                <Route path="/recurring-masses/:id">
                  <NewRecurringMass />
                </Route>
                <Route path="/recurring-masses">
                  <RecurringMassesPage />
                </Route>
                <Route path="/special-masses/new">
                  <EditSpecialMassPage />
                </Route>
                <Route path="/special-masses/:id">
                  <EditSpecialMassPage />
                </Route>
                <Route path="/special-masses">
                  <SpecialMassesPage />
                </Route>
                <Route path="/priests/new">
                  <EditPriestPage />
                </Route>
                <Route path="/priests/:id">
                  <EditPriestPage />
                </Route>
                <Route path="/priests">
                  <PriestsPage />
                </Route>
                <Route path="/admin/users/activity">
                  <ActivityPage />
                </Route>
                <Route path="/admin/users/new">
                  <EditUserPage />
                </Route>
                <Route path="/admin/users/:id">
                  <EditUserPage />
                </Route>
                <Route path="/admin/users">
                  <UsersPage />
                </Route>
                <Route path="/unavailable-dates/new">
                  <NewUnavailableDatesPage />
                </Route>
                <Route path="/unavailable-dates/:id">
                  <EditUnavailableDatesPage />
                </Route>
                <Route path="/unavailable-dates">
                  <UnavailableDatesPage />
                </Route>
                <Route path="/bulletin-printout">
                  <BulletinPrintout />
                </Route>
                <Route path="/intentions-by-presider">
                  <IntentionsByPresider />
                </Route>
                <Route path="/intentions-by-requestor">
                  <IntentionsByRequestor />
                </Route>
                <Route path="/intentions-by-request-date">
                  <IntentionsByRequestDate />
                </Route>
                <Route path="/intentions-without-presider">
                  <IntentionsWithoutPresider />
                </Route>
                <Route path="/new-intention">
                  <NewIntentionPage />
                </Route>
                <Route path="/">
                  <HomePage />
                </Route>
              </Switch>
            </div>
          </Router>
        </>
      )}
    </div>
  );
}

export default App;
