import { Table } from "react-bootstrap";
import { IntentionReportEntry } from "vincent-types/models";
import { formatHistory, formatDateTime } from "../../utils/stringFunctions";

export interface IntentionsTableProps {
  intentions: IntentionReportEntry[];
  error?: string;
  height?: string;
}

export const IntentionsReportTable = (props: IntentionsTableProps) => {
  const { intentions, error } = props;

  return (
    <div
      className="intentions-by-requestor-table-container"
      style={{ maxHeight: props.height || "400px" }}
    >
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Requestor</th>
            <th>Intention</th>
            <th>{`Mass Date & Time`}</th>
            <th>Amount</th>
            <th>Created On / By</th>
          </tr>
        </thead>
        <tbody>
          {!error && intentions.length === 0 && (
            <tr>
              <td colSpan={5} align="center">
                No Intentions Found
              </td>
            </tr>
          )}
          {!error &&
            intentions.map((i) => (
              <tr>
                {i.requestor && <td>{i.requestor}</td>}
                <td>{i.subject}</td>
                <td>{formatDateTime(i.startDateTime)}</td>
                <td>{`$${i.price}`}</td>
                <td>{formatHistory(i.history)}</td>
              </tr>
            ))}
          {error && (
            <tr>
              <td colSpan={5} align="center">
                {error}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
