import { useEffect } from "react";
import ActionBar from "../../../components/action-bar";
import { getPriestsAsync } from "../../../redux/priest-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppState } from "vincent-types/models";
import { Permissions } from "vincent-types/enums";
import { Card, Table } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
function PriestsPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const priests = useSelector((state: AppState) => state.priests.data);
  const error = useSelector((state: AppState) => state.priests.error);
  useEffect(() => {
    dispatch(getPriestsAsync());
  }, [dispatch]);
  return (
    <div>
      <ActionBar
        title="Priests"
        to="/priests/new"
        buttonTitle="New"
        buttonPermissions={[Permissions.CreatePriest]}
      />
      <Card>
        <Card.Body className="new-intention-card">
          <Card.Title>Roster</Card.Title>
          <hr />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {!error &&
                priests &&
                priests.map((p) => (
                  <tr
                    onClick={() => history.push(`${location.pathname}/${p.id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{`${p.title} ${p.firstName} ${p.lastName}`}</td>
                    <td>{p.startDate}</td>
                    <td>{p.endDate}</td>
                  </tr>
                ))}
              {error && (
                <tr>
                  <td colSpan={3} align="center">
                    {error}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}

export default PriestsPage;
