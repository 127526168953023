import { useEffect } from "react";
import ActionBar from "../../../components/action-bar";
import { getPermissionsAsync, getUsersAsync } from "../../../redux/admin-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppState } from "vincent-types/models";
import { Card, Table } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Permissions } from "vincent-types/enums";

function UsersPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const users = useSelector((state: AppState) => state.admin.users.data);
  const permissionGroups = useSelector(
    (state: AppState) => state.admin.permissions.data
  );
  const error = useSelector((state: AppState) => state.admin.users.error);
  useEffect(() => {
    dispatch(getPermissionsAsync());
    dispatch(getUsersAsync());
  }, []);

  const buildPermissionsList = (permissionNames: string[]): string => {
    let permissionsList = "";
    const userPermissionGroups: {
      [key: string]: number;
    } = {};
    permissionNames.forEach((p) => {
      const permissionGroup = permissionGroups.find((g) =>
        g.permissions.map((gp) => gp.name).includes(p)
      );
      if (permissionGroup) {
        if (userPermissionGroups[permissionGroup.name]) {
          userPermissionGroups[permissionGroup.name] += 1;
        } else {
          userPermissionGroups[permissionGroup.name] = 1;
        }
      }
    });
    Object.keys(userPermissionGroups).forEach((k) => {
      if (permissionsList.length > 0) {
        permissionsList = permissionsList.concat(", ");
      }
      permissionsList = permissionsList.concat(
        `${k} (${userPermissionGroups[k]})`
      );
    });
    return permissionsList;
  };

  return (
    <div>
      <ActionBar
        title="Administration"
        to={`${location.pathname}/new`}
        buttonTitle="New"
        buttonPermissions={[Permissions.CreateUser]}
      />
      <Card>
        <Card.Body className="new-intention-card">
          <Card.Title>Users</Card.Title>
          <hr />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Name</th>
                <th>Permissions</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {!error &&
                users &&
                users.map((u) => (
                  <tr
                    onClick={() =>
                      history.push(`${location.pathname}/${u.username}`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <td>{u.username}</td>
                    <td>{`${u.firstName} ${u.lastName}`}</td>
                    <td>{buildPermissionsList(u.permissions)}</td>
                    <td>{u.active ? "Yes" : "No"}</td>
                  </tr>
                ))}
              {error && (
                <tr>
                  <td colSpan={4} align="center">
                    {error}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}

export default UsersPage;
