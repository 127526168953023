import axios from "axios";
import { useEffect, useState } from "react";
import { Card, Table, Spinner, Button } from "react-bootstrap";
import { ConflictingMass } from "vincent-types/models";
import { formatMassTime } from "../../../../../utils/stringFunctions";

interface ConflictsProps {
  height?: number;
  startDate: string;
  endDate: string;
  onNext: (conflicts: ConflictingMass[]) => void;
  onCancel: () => void;
  onBack: () => void;
}

function Conflicts(props: ConflictsProps) {
  const { startDate, endDate } = props;
  const [conflicts, setConflicts] = useState<ConflictingMass[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Get conflicting masses
    const getConflicts = async () => {
      try {
        setError("");
        setLoading(true);
        const response = await axios.get(
          `/api/mass/instances/conflicts?start=${startDate}&end=${endDate}`
        );
        setConflicts(response.data);
      } catch (err) {
        setError("Error loading conflicts");
      } finally {
        setLoading(false);
      }
    };
    getConflicts();
  }, [startDate, endDate]);
  return (
    <Card>
      <Card.Body className="new-intention-card">
        <Card.Title>Conflicting Intentions</Card.Title>
        <div
          className="new-intention-table-container"
          style={{ maxHeight: props.height || "400px" }}
        >
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Intention</th>
                <th>Requestor</th>
                <th>{`Current Date & Time`}</th>
                <th>{`Suggested New Date & Time`}</th>
              </tr>
            </thead>
            <tbody>
              {!error &&
                conflicts.length > 0 &&
                conflicts.map((c: ConflictingMass) => (
                  <tr>
                    <td>{c.mass.intention?.subject}</td>
                    <td>{c.mass.intention?.requestor}</td>
                    <td>{formatMassTime(c.mass)}</td>
                    <td>{formatMassTime(c.suggestedMass)}</td>
                  </tr>
                ))}
              {!error && conflicts.length === 0 && !loading && (
                <tr>
                  <td colSpan={4} align="center">
                    No conflicting intentions found
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan={4} align="center">
                    <Spinner size="sm" animation="border" />
                  </td>
                </tr>
              )}
              {error && (
                <tr>
                  <td colSpan={4} align="center">
                    {error}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div className="new-intention-button-group">
          <Button variant="outline-secondary" onClick={props.onCancel}>
            Cancel
          </Button>
          <div className="new-intention-button-spacer" />
          <Button variant="outline-primary" onClick={props.onBack}>
            Back
          </Button>
          <div className="new-intention-button-spacer" />
          <Button
            disabled={error.length > 0 || loading}
            variant="success"
            onClick={() => props.onNext(conflicts)}
          >
            Next
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default Conflicts;
