import { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Alert,
  Badge,
} from "react-bootstrap";
import { IntentionsReceiptPrintout } from "../../../components/print-components/intention-receipt";
import { IntentionsTable } from "../";
import { formatRequestorName } from "vincent-utils";
import {
  IntentionCart,
  Language,
  RequestedIntention,
} from "vincent-types/models";
import { formatValue } from "react-currency-input-field";
import moment from "moment";
import axios from "axios";
import { handleAxiosError } from "../../../utils/network";

interface SummaryProps {
  cart: IntentionCart;
  onCancel: () => void;
  onBack: () => void;
}

interface IntentionPost {
  date: string;
  massId: number;
  requestor: string;
  preferredLanguages: Language[];
  phone: string;
  subject: string;
  deceased: boolean;
}

function Summary(props: SummaryProps) {
  const { cart } = props;
  const componentRef = useRef<any>();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [formattingApplied, setFormattingApplied] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const name = buildRequestorName(cart);
    if (name !== cart.requestor.name) {
      setFormattingApplied(true);
    }
  }, [cart]);

  const buildRequestorName = (cart: IntentionCart): string => {
    return formatRequestorName(cart.requestor.name);
  };

  const save = async () => {
    setSaving(true);
    setError("");
    try {
      const intentionsToPost: IntentionPost[] = cart.intentions.map(
        (i: RequestedIntention) => {
          return {
            date: moment(i.dateTime).format("YYYY-MM-DD"),
            massId: i.massId,
            requestor: buildRequestorName(cart),
            phone: cart.requestor.phone,
            subject: i.subject,
            deceased: i.deceased,
            payment: i.price,
            preferredLanguages: cart.requestor.languages,
          };
        }
      );
      await axios.post("/api/intentions", intentionsToPost);
      setSuccess(true);
    } catch (err) {
      handleAxiosError(err, "Error saving intention", setError);
    } finally {
      setSaving(false);
    }
  };
  return (
    <Card>
      <Card.Body className="new-intention-card">
        <Card.Title>Summary</Card.Title>
        <hr />
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group controlId="formRequestor">
            <Form.Label>
              Requestor{" "}
              {formattingApplied ? (
                <Badge variant="warning" style={{ marginTop: "-20px" }}>
                  Formatting Applied
                </Badge>
              ) : (
                ""
              )}
            </Form.Label>
            <Form.Control
              value={`${buildRequestorName(cart)} ${cart.requestor.phone}`}
              type="text"
            />
          </Form.Group>
          <Form.Group controlId="formIntentions">
            <Form.Label>{`Intentions (${cart.intentions.length})`}</Form.Label>
            <IntentionsTable intentions={cart.intentions} height="200px" />
          </Form.Group>
          <div className="new-intention-payment-summary-container">
            <Table size="sm" style={{ width: "200px" }} borderless>
              <tr>
                <td
                  align="right"
                  style={{ width: "60%", verticalAlign: "middle" }}
                >
                  Total:
                </td>
                <td align="right">
                  <span className="new-intention-money">
                    {Number(
                      cart.intentions
                        .map((i) => Number.parseInt(i.price))
                        .reduce((a, b) => a + b, 0)
                    )
                      .toFixed(2)
                      .toLocaleString()}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  align="right"
                  style={{ width: "60%", verticalAlign: "middle" }}
                >
                  Amount Paid:
                </td>
                <td align="right">
                  <span>
                    {formatValue({
                      value: cart.payment,
                      groupSeparator: ",",
                      decimalSeparator: ".",
                      prefix: "$",
                    })}
                  </span>
                </td>
              </tr>
            </Table>
          </div>
        </Form>
      </Card.Body>
      {!success && (
        <div className="new-intention-button-group">
          <Button variant="outline-secondary" onClick={props.onCancel}>
            Cancel
          </Button>
          <div className="new-intention-button-spacer" />
          <Button variant="outline-primary" onClick={props.onBack}>
            Back
          </Button>
          <div className="new-intention-button-spacer" />
          <Button variant="success" onClick={save} disabled={saving}>
            {saving ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      )}
      {success && (
        <div className="new-intention-button-group">
          <Button variant="outline-primary" onClick={props.onCancel}>
            Close
          </Button>
          <div className="new-intention-button-spacer" />
          <Button variant="success" onClick={handlePrint}>
            Print
          </Button>
        </div>
      )}
      <div style={{ display: "none" }}>
        <IntentionsReceiptPrintout
          ref={componentRef}
          requestor={`${cart.requestor.name} ${cart.requestor.phone}`}
          total={Number(
            cart.intentions
              .map((i) => Number.parseInt(i.price))
              .reduce((a, b) => a + b, 0)
          )}
          paid={cart.payment}
          intentions={cart.intentions}
        />
      </div>
    </Card>
  );
}

export default Summary;
