import moment from "moment";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../utils/stringFunctions";
import { CgCross } from "react-icons/cg";
import { BiErrorCircle } from "react-icons/bi";
import { AppState, Mass, Priest } from "vincent-types/models";
import "./styles.css";

interface PortraitWeekProps {
  onSelect: (mass: Mass) => void;
}

// const days = [
//   "SUNDAY",
//   "MONDAY",
//   "TUESDAY",
//   "WEDNESDAY",
//   "THURSDAY",
//   "FRIDAY",
//   "SATURDAY",
// ];

const buildDay = (
  onClick: (mass: Mass) => void,
  d: string,
  masses: Mass[],
  priests: Priest[]
) => {
  const getPriestName = (id: number): string => {
    if (!id) {
      return "---";
    }
    const priest = priests.find((p) => p.id === id);
    if (priest) {
      return `${priest.title} ${priest.firstName}`;
    }
    return "Unknown";
  };
  return (
    <table className="day-card">
      <tbody>
        <tr>
          <td
            width="18%"
            valign="middle"
            style={{ textAlign: "center" }}
            rowSpan={masses.length > 7 ? masses.length : 7}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: "32px" }}>
                {moment(d).format("dddd")}
              </span>
              <span style={{ fontSize: "26px" }}>
                {moment(d).format("MMM Do")}
              </span>
            </div>
          </td>
          <td style={{ padding: "5px" }}>
            <table className="portrait-week-mass-list">
              <tbody>
                <tr style={{ borderTop: "none", fontWeight: "bold" }}>
                  <td></td>
                  <td>Intention</td>
                  <td>Requestor</td>
                  <td>Presider</td>
                </tr>
                {masses.map((m, i) => {
                  return (
                    <>
                      {m.priestsUnavailable ? (
                        <tr>
                          <td
                            width="10%"
                            height="50px"
                            valign="middle"
                            style={{ paddingLeft: "5px" }}
                          >
                            <span style={{ fontSize: "18px" }}>
                              {m &&
                                moment(
                                  m.startDateTime,
                                  "YYYY-MM-DD h:mma"
                                ).format("h:mma")}
                            </span>
                            <br />
                            <span style={{ fontSize: "14px" }}>
                              {m && capitalizeFirstLetter(m.language.name)}
                            </span>
                          </td>
                          <td colSpan={3} valign="middle" align="center">
                            Prayer Service Only
                          </td>
                        </tr>
                      ) : (
                        <tr
                          className={`${
                            m.intention
                              ? "portrait-week-mass-row"
                              : "portrait-week-filler-row"
                          }`}
                          onClick={m ? () => onClick(m) : () => {}}
                        >
                          <td
                            width="10%"
                            height="50px"
                            valign="middle"
                            style={{ paddingLeft: "5px" }}
                          >
                            <span style={{ fontSize: "18px" }}>
                              {m &&
                                moment(
                                  m.startDateTime,
                                  "YYYY-MM-DD h:mma"
                                ).format("h:mma")}
                            </span>
                            <br />
                            <span style={{ fontSize: "14px" }}>
                              {m && capitalizeFirstLetter(m.language.name)}
                            </span>
                          </td>
                          <td width="30%" valign="middle">
                            {m?.intention?.subject}
                            {m?.intention?.deceased && (
                              <CgCross
                                size="18px"
                                style={{ marginTop: "-5px" }}
                              />
                            )}
                          </td>
                          <td width="30%" valign="middle">
                            {m?.intention?.requestor}
                          </td>
                          <td width="30%" valign="middle">
                            {m &&
                              m.intention &&
                              getPriestName(m?.intention?.priest?.id || 0)}
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
                {masses.length < 3 &&
                  [...Array(3 - masses.length)].map((m) => {
                    return (
                      <tr className="portrait-week-filler-row">
                        <td height="50px"></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

function PortraitWeek(props: PortraitWeekProps) {
  const { masses, error } = useSelector((state: AppState) => state.week);
  const priests = useSelector((state: AppState) => state.priests.data);

  return (
    <div className="portrait-week-container">
      {masses &&
        Object.keys(masses).map((d) => {
          const massesForDay = masses[d];
          return (
            <div className="portrait-week-day-card">
              {buildDay(props.onSelect, d, massesForDay, priests)}
            </div>
          );
        })}
      {!masses && !error && (
        <div className="portrait-week-spinner">
          <Spinner animation="border" />
        </div>
      )}
      {error && (
        <div className="portrait-week-error">
          <div>
            <BiErrorCircle className="portrait-week-error-icon" />
            <br />
            {error}
          </div>
        </div>
      )}
    </div>
  );
}

export default PortraitWeek;
