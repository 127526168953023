import { useEffect } from "react";
import ActionBar from "../../../components/action-bar";
import { getRecurringMassesAsync } from "../../../redux/mass-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppState } from "vincent-types/models";
import { Permissions } from "vincent-types/enums";
import { Card, Table } from "react-bootstrap";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { findMostEffectiveStartTime } from "../../../utils/massFunctions";
import "./styles.css";

function RecurringMassesPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const masses = useSelector((state: AppState) => state.masses.recurring.data);
  const error = useSelector((state: AppState) => state.masses.recurring.error);
  useEffect(() => {
    dispatch(getRecurringMassesAsync());
  }, [dispatch]);

  const handleClick = (id: number) => {
    history.push(`${location.pathname}/${id}`);
  };
  return (
    <div>
      <ActionBar
        title="Masses"
        to="/recurring-masses/0"
        buttonTitle="New"
        buttonPermissions={[Permissions.CreateRecurringMass]}
      />
      <Card>
        <Card.Body className="new-intention-card">
          <Card.Title>Recurring</Card.Title>
          <hr />
          <div
            className="new-intention-table-container"
            style={{ maxHeight: "400px" }}
          >
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Mass Day</th>
                  <th>Current Effective Time</th>
                  <th>Language</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {!error &&
                  masses &&
                  [...masses]
                    .sort((a, b) => {
                      if (a.day.number === b.day.number) {
                        return findMostEffectiveStartTime(a.times, moment()) <=
                          findMostEffectiveStartTime(b.times, moment())
                          ? -1
                          : 1;
                      } else {
                        return a.day.number <= b.day.number ? -1 : 1;
                      }
                    })
                    .map((m) => (
                      <tr
                        onClick={() => handleClick(m.id)}
                        className="masses-recurring-table-row"
                      >
                        <td>{m.recurrence.description}</td>
                        <td>
                          {moment(
                            findMostEffectiveStartTime(m.times, moment()),
                            "HH:mm a"
                          ).format("h:mm a")}
                        </td>
                        <td>{m.language.name}</td>
                        <td>{m.effectiveDate}</td>
                        <td>{m.endDate}</td>
                      </tr>
                    ))}
                {error && (
                  <tr>
                    <td colSpan={5} align="center">
                      {error}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RecurringMassesPage;
