// import store from '../redux/store';
import axios from "axios";

export const myFetch = (path: string, options?: any): Promise<Response> => {
  // const token = store?.getState().user.token;
  return fetch(path, {
    ...options,
    headers: {
      ...options.headers,
      // Authorization: `Bearer ${token}`
    },
  });
};

export const handleAxiosError = (
  err: unknown,
  message: string,
  setError?: (message: string) => void
) => {
  let errorMessage = message;
  if (axios.isAxiosError(err)) {
    errorMessage = err.response?.data.error || message;
  }
  if (setError) {
    setError(errorMessage);
  }
};
