import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./user-slice";
import weekReducer from "./week-slice";
import massReducer from "./mass-slice";
import priestReducer from "./priest-slice";
import adminReducer from "./admin-slice";
import unavailableDateReducer from "./unavailable-date-slice";
import { AppState } from "vincent-types/models";
import { ActionType } from "./types/action.types";
import { AnyAction, Reducer } from "redux";

const LOCAL_STORAGE_NAME = "vincent-intentions-persisted-state";

let PERSISTED_STATE = "";
let SHOULD_PERSIST_STATE = false;

interface ReducerMap {
  user: typeof userReducer;
  week: typeof weekReducer;
  masses: typeof massReducer;
  admin: typeof adminReducer;
  priests: typeof priestReducer;
  unavailableDates: typeof unavailableDateReducer;
}

const saveToLocalStorage = (state: AppState) => {
  try {
    if (SHOULD_PERSIST_STATE) {
      const serializedState = JSON.stringify(state);
      if (serializedState !== PERSISTED_STATE) {
        localStorage.setItem(LOCAL_STORAGE_NAME, serializedState);
        PERSISTED_STATE = serializedState;
      }
    }
  } catch (e) {
    console.warn(e);
  }
};

const clearLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_NAME);
  PERSISTED_STATE = "";
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_NAME);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};

const createStore = () => {
  const reducers: ReducerMap = {
    user: userReducer,
    week: weekReducer,
    admin: adminReducer,
    masses: massReducer,
    priests: priestReducer,
    unavailableDates: unavailableDateReducer,
  };

  let rootReducer = combineReducers<AppState>(reducers);

  const reduce: Reducer<AppState, AnyAction> = (
    state: AppState | undefined,
    action
  ) => {
    const clonedState: AppState | undefined =
      state && action.type !== ActionType.logout ? { ...state } : undefined;
    if (ActionType.logout === action.type) {
      SHOULD_PERSIST_STATE = false;
      clearLocalStorage();
    }

    if (ActionType.login === action.type) {
      SHOULD_PERSIST_STATE = true;
    }

    return rootReducer(clonedState, action);
  };

  const store = configureStore({
    reducer: reduce,
    preloadedState: loadFromLocalStorage(),
    devTools: process.env.NODE_ENV !== "production",
  });

  store.subscribe(() => saveToLocalStorage(store.getState()));

  return store;
};

export default createStore();
