import { useState } from "react";
import {
  loginAsync,
  resetPasswordAsync,
  setPasswordResetError,
} from "../../redux/user-slice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Alert } from "react-bootstrap";
import { AppState } from "vincent-types/models";
import "./styles.css";
import { LoginStatuses } from "vincent-types/enums";

function ResetPasswordForm(props: { username: string }) {
  const dispatch = useDispatch();
  const username = props.username;
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const pending = useSelector(
    (state: AppState) => state.user.login.status === LoginStatuses.LoggingIn
  );

  const submit = () => {
    if (newPassword !== confirmPassword) {
      dispatch(setPasswordResetError("New & Confirm passwords do not match"));
      setOldPassword("");
      setNewPassword("");
    } else {
      dispatch(resetPasswordAsync({ username, oldPassword, newPassword }));
    }
  };

  return (
    <form>
      <h3>Reset Password</h3>
      <div className="form-group">
        <label>Old Password</label>
        <input
          type="password"
          className="form-control"
          value={oldPassword}
          onChange={(event) => {
            setOldPassword(event.target.value);
          }}
        />
      </div>
      <div className="form-group">
        <label>New Password</label>
        <input
          type="password"
          className="form-control"
          value={newPassword}
          onChange={(event) => {
            setNewPassword(event.target.value);
          }}
        />
      </div>
      <div className="form-group">
        <label>Confirm New Password</label>
        <input
          type="password"
          className="form-control"
          value={confirmPassword}
          onChange={(event) => {
            setConfirmPassword(event.target.value);
          }}
        />
      </div>
      <button
        onClick={submit}
        type="button"
        className="btn btn-success btn-lg btn-block"
      >
        {pending ? <Spinner size="sm" animation="border" /> : "Sign in"}
      </button>
    </form>
  );
}

function LoginForm(props: { onSetUsername: (username: string) => void }) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const pending = useSelector(
    (state: AppState) => state.user.login.status === LoginStatuses.LoggingIn
  );
  const submit = (event: any) => {
    event.preventDefault();
    if (props.onSetUsername) {
      props.onSetUsername(username);
    }
    dispatch(loginAsync({ username, password }));
  };

  return (
    <form onSubmit={submit}>
      <h3>St Vincent de Paul</h3>
      <div className="form-group">
        <label>Username</label>
        <input
          autoFocus={true}
          type="text"
          className="form-control"
          placeholder="Enter username"
          value={username}
          onChange={(event) => {
            setUsername(event.target.value);
          }}
        />
      </div>
      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Enter password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
      </div>
      <button
        // onClick={submit}

        type="submit"
        className="btn btn-success btn-lg btn-block"
      >
        {pending ? <Spinner size="sm" animation="border" /> : "Sign in"}
      </button>
    </form>
  );
}

function LoginPage() {
  const resetRequired = useSelector(
    (state: AppState) => state.user.login.passwordResetRequired
  );
  const error = useSelector((state: AppState) => state.user.login.error);
  const [username, setUsername] = useState("");

  return (
    <div className="outer">
      {error && (
        <div className="alert-fixed">
          <Alert variant="danger">{error}</Alert>
        </div>
      )}
      <div className="inner">
        {resetRequired ? (
          <ResetPasswordForm username={username} />
        ) : (
          <LoginForm onSetUsername={setUsername} />
        )}
      </div>
    </div>
  );
}

export default LoginPage;
