import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { User, AppState, PermissionGroup } from "vincent-types/models";
import { useSelector } from "react-redux";
import Button from "../../../../components/permission-based-button";
import {
  Card,
  Alert,
  Row,
  Col,
  Form,
  Spinner,
  Modal,
  Container,
} from "react-bootstrap";
import axios from "axios";
import _ from "lodash";
import { Permissions } from "vincent-types/enums";
import { handleAxiosError } from "../../../../utils/network";

const INITIAL_USER: User = {
  username: "",
  firstName: "",
  lastName: "",
  active: true,
  roles: [],
  preferences: {},
  permissions: [],
};

enum Controls {
  FirstName = "FirstName",
  LastName = "LastName",
  RadioActive = "RadioActive",
  RadioNotActive = "RadioNotActive",
  Username = "Username",
  Role = "Role",
  Permissions = "Permissions",
}

function ConfirmPasswordResetModal(props: {
  show: boolean;
  reset: () => void;
  onHide: () => void;
  name: string;
}) {
  const [saving, setSaving] = useState(false);
  // const [newPassword, setNewPassword] = useState("");
  const yes = () => {
    setSaving(true);
    if (props.reset) {
      props.reset();
    }
    setSaving(false);
    if (props.onHide) {
      props.onHide();
    }
  };
  return (
    <Modal {...props} show={props.show} size="lg" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {saving ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" />
          </div>
        ) : (
          <span>
            Are you sure you want to reset the password for {props.name}?
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={yes}>Yes</Button>
        <Button onClick={props.onHide}>No</Button>
      </Modal.Footer>
    </Modal>
  );
}

function PasswordModal(props: { newPassword: string; onHide: () => void }) {
  return (
    <Modal
      {...props}
      show={props.newPassword && props.newPassword.length > 0}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <span>
          The user's password has been set to <code>{props.newPassword}</code>.
          The user will be required to reset the password after next login.
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function PermissionGroupContainer(props: {
  group: PermissionGroup;
  user: User;
  onUpdate: (e: any) => void;
  onGroupCheck: (e: any, permissions: string[]) => void;
}) {
  console.log(
    `onGroupContainer Render: [${props.user?.permissions?.join(",")}]`
  );
  const { group, user, onUpdate } = props;
  const [groupChecked, setGroupChecked] = useState(false);

  useEffect(() => {
    setGroupChecked(
      user.permissions.some((p) =>
        group.permissions.map((p1) => p1.name).includes(p)
      )
    );
  }, [group, user]);

  const onGroupCheck = (e: any) => {
    const { checked } = e.target;
    props.onGroupCheck(
      checked,
      checked
        ? group.permissions.filter((p) => p.default).map((p) => p.name)
        : group.permissions
            .filter((p) => user.permissions.includes(p.name))
            .map((p) => p.name)
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h5 style={{ color: group.enabled ? "black" : "gray" }}>
            <input
              disabled={!group.enabled}
              type="checkbox"
              style={{ marginRight: "10px" }}
              checked={groupChecked}
              name={group.name}
              onChange={onGroupCheck}
            />
            {group.name}
          </h5>
        </Col>
      </Row>
      <Row>
        {group.permissions.map((p) => {
          return (
            <Col xs={3}>
              <Form.Group controlId="formPermissions">
                <Form.Check
                  type="checkbox"
                  label={p.description}
                  name={Controls.Permissions}
                  value={p.name}
                  onChange={onUpdate}
                  checked={user.permissions.includes(p.name)}
                  disabled={p.default || !groupChecked}
                />
              </Form.Group>
            </Col>
          );
        })}
      </Row>
      <hr />
    </Container>
  );
}

function EditUser() {
  const [error, setError] = useState("");
  const history = useHistory();
  const params = useParams<{ id: string | undefined }>();
  const [newPassword, setNewPassword] = useState("");
  const [saving, setSaving] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);
  const id = params.id;
  const title = () => {
    if (id) {
      return "Edit User";
    }
    return "New User";
  };
  const buildUser = (users: User[]) => {
    if (!users) {
      return INITIAL_USER;
    }
    let user = users.find((u) => u.username === id);
    if (!user) {
      return INITIAL_USER;
    }
    return user;
  };
  const [user, setUser] = useState(
    buildUser(useSelector((state: AppState) => state.admin.users.data))
  );
  const permissionGroups = useSelector(
    (state: AppState) => state.admin.permissions.data
  );
  const canSave = () => {
    return (
      user && user.username && user.firstName && user.lastName
      // Not sure a person has to have any permissions since anyone with a valid token can
      // at least view permissions
      // user.permissions &&
      // user.permissions.length > 0
    );
  };
  const resetPassword = async () => {
    try {
      setError("");
      setSaving(true);
      const resp = await axios.get(`/api/users/${user.username}/reset`);
      setNewPassword(resp.data.newPassword);
    } catch (err) {
      handleAxiosError(err, "Error resetting password for user", setError);
    } finally {
      setSaving(false);
    }
  };
  const save = async () => {
    try {
      setError("");
      setSaving(true);
      if (!id) {
        const resp = await axios.post("/api/users", user);
        setNewPassword(resp.data.newPassword);
      } else {
        await axios.put("/api/users", user);
        history.push("/admin/users");
      }
    } catch (err) {
      handleAxiosError(err, "Error saving user information", setError);
    } finally {
      setSaving(false);
    }
  };
  const onClosePasswordModal = () => {
    setNewPassword("");
    history.goBack();
  };
  const onUpdate = (e: any) => {
    const { name, value, checked } = e.target;
    let updatedUser = _.cloneDeep(user);
    switch (name) {
      case Controls.FirstName:
        updatedUser.firstName = value;
        break;
      case Controls.LastName:
        updatedUser.lastName = value;
        break;
      case Controls.Username:
        updatedUser.username = value;
        break;
      case Controls.Role:
        if (checked) {
          updatedUser.roles.push(value);
        } else {
          const index = updatedUser.roles.findIndex((u) => u === value);
          updatedUser.roles.splice(index, 1);
        }
        break;
      case Controls.Permissions:
        if (checked) {
          updatedUser.permissions.push(value);
        } else {
          const index = updatedUser.permissions.findIndex((p) => p === value);
          updatedUser.permissions.splice(index, 1);
        }
        break;
    }
    console.log(updatedUser);
    setUser(updatedUser);
  };

  const handleGroupCheck = (checked: boolean, permissions: string[]) => {
    let updatedUser = _.cloneDeep(user);
    if (checked) {
      permissions.forEach((p) => updatedUser.permissions.push(p));
    } else {
      permissions.forEach((p) => {
        const index = updatedUser.permissions.findIndex((u) => u === p);
        updatedUser.permissions.splice(index, 1);
      });
    }
    setUser(updatedUser);
  };

  return (
    <div>
      <h2>{title()}</h2>
      <Card>
        <Card.Body className="new-intention-card">
          {error && <Alert variant={"danger"}>{error}</Alert>}
          {id && (
            <>
              <Card.Title>Active</Card.Title>
              <hr />
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    name={Controls.RadioActive}
                    type="radio"
                    checked={user.active}
                    id="radio-btn-active"
                  />
                  <Form.Check
                    inline
                    label="No"
                    name={Controls.RadioNotActive}
                    type="radio"
                    checked={!user.active}
                    id="radio-btn-not-active"
                  />
                </Col>
              </Row>
              <br />
            </>
          )}
          <Card.Title>Name</Card.Title>
          <hr />
          <Row md={3}>
            <Col>
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  value={user.firstName}
                  type="text"
                  onChange={onUpdate}
                  name={Controls.FirstName}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  value={user.lastName}
                  type="text"
                  onChange={onUpdate}
                  name={Controls.LastName}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Card.Title>Username</Card.Title>
          <hr />
          <Row md={3}>
            <Col>
              <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  value={user.username}
                  type="text"
                  readOnly={id !== undefined}
                  name={Controls.Username}
                  onChange={onUpdate}
                />
              </Form.Group>
            </Col>
            {id && (
              <Col md={2}>
                <Form.Group controlId="formUsername">
                  <Form.Label>&nbsp;</Form.Label>
                  <Button
                    className="form-control"
                    onClick={() => setConfirmReset(true)}
                  >
                    Reset Password..
                  </Button>
                </Form.Group>
              </Col>
            )}
          </Row>
          <br />
          <Card.Title>Permissions</Card.Title>
          <hr />
          {[...permissionGroups]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((p) => {
              return (
                <PermissionGroupContainer
                  user={user}
                  group={p}
                  onUpdate={onUpdate}
                  onGroupCheck={handleGroupCheck}
                />
              );
            })}
          <div className="new-recurring-mass-button-group">
            <Button
              variant="outline-secondary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={save}
              disabled={!canSave()}
              permissions={[Permissions.EditUser, Permissions.CreateUser]}
            >
              {saving ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Card.Body>
      </Card>
      <ConfirmPasswordResetModal
        reset={resetPassword}
        name={`${user.firstName} ${user.lastName}`}
        onHide={() => setConfirmReset(false)}
        show={confirmReset}
      />
      <PasswordModal newPassword={newPassword} onHide={onClosePasswordModal} />
    </div>
  );
}

export default EditUser;
