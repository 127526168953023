import { useState } from "react";
import { Card, Button, Table } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { IntentionCart } from "vincent-types/models";
import { IntentionsTable } from "../";

interface PaymentDetailsProps {
  cart: IntentionCart;
  onNext: (payment: string) => void;
  onBack: () => void;
  onCancel: () => void;
}

function PaymentDetails(props: PaymentDetailsProps) {
  const [payment, setPayment] = useState(props.cart.payment);
  const [intentions] = useState(props.cart.intentions);
  const savePayment = () => {
    props.onNext(payment);
  };
  return (
    <Card>
      <Card.Body className="new-intention-card">
        <Card.Title>Payment</Card.Title>
        <hr />
        <IntentionsTable intentions={intentions} />
        <div className="new-intention-payment-summary-container">
          <Table size="sm" style={{ width: "300px" }} borderless>
            <tr>
              <td
                align="right"
                style={{ width: "50%", verticalAlign: "middle" }}
              >
                Total:
              </td>
              <td align="right" style={{ paddingRight: "17px" }}>
                <span className="new-intention-money">
                  {Number(
                    intentions
                      .map((i) => Number.parseInt(i.price))
                      .reduce((a, b) => a + b, 0)
                  )
                    .toFixed(2)
                    .toLocaleString()}
                </span>
              </td>
            </tr>
            <tr>
              <td
                align="right"
                style={{ width: "50%", verticalAlign: "middle" }}
              >
                Amount Paid:
              </td>
              <td>
                <CurrencyInput
                  className="form-control"
                  style={{ textAlign: "right" }}
                  id="payment-amount"
                  name="payment-amount"
                  placeholder=""
                  defaultValue={""}
                  prefix="$"
                  decimalsLimit={2}
                  onValueChange={(value, name) => setPayment(value || "")}
                />
              </td>
            </tr>
          </Table>
        </div>
      </Card.Body>
      <div className="new-intention-button-group">
        <Button variant="outline-secondary" onClick={props.onCancel}>
          Cancel
        </Button>
        <div className="new-intention-button-spacer" />
        <Button variant="outline-primary" onClick={props.onBack}>
          Back
        </Button>
        <div className="new-intention-button-spacer" />
        <Button variant="success" disabled={!payment} onClick={savePayment}>
          Next
        </Button>
      </div>
    </Card>
  );
}

export default PaymentDetails;
