import moment, { Moment } from "moment";
import React from "react";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import { Spinner } from "react-bootstrap";
import "./styles.css";

interface CalendarProps {
  date: string | Moment;
  onSelectDate: (date: string) => void;
  onBack?: () => void;
  onForward?: () => void;
  unavailableDates?: string[];
  showNavigation?: boolean;
  loading?: boolean;
}

function calendar(props: CalendarProps) {
  const firstDay = 1;
  const lastDay = moment(props.date).endOf("month").daysInMonth();
  const startDate = moment(props.date).startOf("month").day();
  const endDate = moment(props.date).endOf("month").daysInMonth() + startDate;
  const year = moment(props.date).startOf("month").year();
  const month = moment(props.date).startOf("month").month();

  const onClick = (date: string) => {
    if (props.onSelectDate) {
      props.onSelectDate(date);
    }
  };

  const onBack = () => {
    props.onBack && props.onBack();
  };

  const onForward = () => {
    props.onForward && props.onForward();
  };

  const dateIsAvailable = (date: string) => {
    if (
      !date ||
      !props.unavailableDates ||
      !Array.isArray(props.unavailableDates)
    ) {
      return true;
    }
    if (props.unavailableDates.includes(date)) {
      return false;
    }
    return true;
  };

  return (
    <div className="calendar-container">
      <table width="100%" className="calendar-table">
        <tr className="calendar-title-row">
          <td>
            {props.showNavigation && (
              <BsFillCaretLeftFill
                onClick={onBack}
                style={{ marginTop: "-5px" }}
              />
            )}
          </td>
          <td colSpan={5}>{moment(props.date).format("MMM YYYY")}</td>
          <td>
            {props.showNavigation && (
              <BsFillCaretRightFill
                onClick={onForward}
                style={{ marginTop: "-5px" }}
              />
            )}
          </td>
        </tr>
        <tr className="day-name-row">
          <td>Sun</td>
          <td>Mon</td>
          <td>Tue</td>
          <td>Wed</td>
          <td>Thu</td>
          <td>Fri</td>
          <td>Sat</td>
        </tr>
        {props.loading && (
          <tr style={{ height: "200px" }}>
            <td colSpan={7} valign="middle">
              <Spinner animation="border" />
            </td>
          </tr>
        )}
        {!props.loading &&
          [0, 1, 2, 3, 4, 5].map((w) => {
            return (
              <tr className="day-row">
                {[1, 2, 3, 4, 5, 6, 7].map((d) => {
                  let day;
                  if (w * 7 + d <= startDate) {
                    day = firstDay;
                  } else if (w * 7 + d > endDate) {
                    day = lastDay;
                  } else {
                    day = w * 7 + d - startDate;
                  }
                  const date = moment({
                    year,
                    month,
                    day,
                  }).format("YYYY-MM-DD");
                  return (
                    <td
                      onClick={() => onClick(date)}
                      className={
                        !props.showNavigation
                          ? ""
                          : dateIsAvailable(date)
                          ? "calendar-available-date"
                          : "calendar-unavailable-date"
                      }
                    >
                      {w * 7 + d > startDate && w * 7 + d <= endDate
                        ? w * 7 + d - startDate
                        : ""}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </table>
    </div>
  );
}

export default calendar;
