import React from "react";
import "./styles.css";
import { Table } from "react-bootstrap";
import {
  formatDateTime,
  formatHistory,
  formatOnlyDate,
} from "../../../utils/stringFunctions";

export interface IntentionsByRequestDateData {
  printDateTime?: string;
  requestStartDate: string;
  requestEndDate: string;
  intentions: {
    requestor: string;
    subject: string;
    startDateTime: string;
    price: string;
    history?: {
      createDateTime: string;
      createUsername: string;
    };
  }[];
}

interface IntentionsByRequestDatePrintoutProps {
  report: IntentionsByRequestDateData;
}

export class IntentionsByRequestDatePrintout extends React.PureComponent<IntentionsByRequestDatePrintoutProps> {
  render() {
    const { report } = this.props;
    return (
      <div className="intentions-by-presider-print-report-container">
        <h2>Intentions By Request Date</h2>
        <hr />
        <h2>Report Date & Time</h2>
        <h3>{report.printDateTime}</h3>
        <hr />
        <h2>Request Date Range</h2>
        <h3>{`${formatOnlyDate(report.requestStartDate)} - ${formatOnlyDate(
          report.requestEndDate
        )}`}</h3>
        <hr />
        <h2>Total</h2>
        <h3 className="new-intention-money">
          {Number(
            report.intentions
              .map((i) => Number.parseInt(i.price))
              .reduce((a, b) => a + b, 0)
          )
            .toFixed(2)
            .toLocaleString()}
        </h3>
        <div className="intentions-by-presider-print-table-container">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Requestor</th>
                <th>Intention</th>
                <th>{`Mass Date & Time`}</th>
                <th>Amount</th>
                <th>{`Created On / By`}</th>
              </tr>
            </thead>
            <tbody>
              {report.intentions.map((i) => (
                <tr>
                  <td>{i.requestor}</td>
                  <td>{i.subject}</td>
                  <td>{formatDateTime(i.startDateTime)}</td>
                  <td>{`$${i.price}`}</td>
                  <td>{formatHistory(i.history)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
