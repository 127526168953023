import { Card, Form, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Spinner, Alert } from "react-bootstrap";
import { SpecialMassTemplate, AppState } from "vincent-types/models";
import { Permissions } from "vincent-types/enums";
import _ from "lodash";
import "./styles.css";
import axios from "axios";
import { useSelector } from "react-redux";
import Button from "../../../../components/permission-based-button";
import { handleAxiosError } from "../../../../utils/network";

enum Controls {
  MassDate = "MassDate",
  MassTime = "MassTime",
  Language = "Language",
  Notes = "Notes",
}

const Languages = [
  {
    name: "Select",
    value: -1,
  },
  {
    name: "English",
    value: 1,
  },
  {
    name: "Spanish",
    value: 2,
  },
  {
    name: "Bilingual",
    value: 3,
  },
  {
    name: "Vietnamese",
    value: 4,
  },
];

interface SpecialMass {
  id: number;
  notes: string;
  date: string;
  time: string;
  language: number;
}

const INITIAL_STATE: SpecialMass = {
  id: 0,
  notes: "",
  date: "",
  time: "",
  language: -1,
};

function EditSpecialMass() {
  const params = useParams<{ id: string | undefined }>();
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");

  const buildInitialState = (masses: SpecialMassTemplate[]) => {
    if (!masses) {
      return INITIAL_STATE;
    }
    const myMass = masses.find(
      (s) => s.id === Number.parseInt(params.id || "")
    );
    if (myMass) {
      return {
        id: myMass.id,
        date: myMass.startDateTime.split(" ")[0],
        time: myMass.startDateTime.split(" ")[1],
        language: myMass.language.id,
        notes: myMass.notes,
      };
    }
    return INITIAL_STATE;
  };

  const [specialMass, setSpecialMass] = useState<SpecialMass>(
    useSelector((state: AppState) =>
      buildInitialState(state.masses.special.data)
    )
  );

  const history = useHistory();

  const onSelectValue = (event: any) => {
    let updatedMass = _.cloneDeep(specialMass);
    switch (event.target.name) {
      case Controls.MassDate:
        updatedMass.date = event.target.value;
        setSpecialMass(updatedMass);
        break;
      case Controls.Language:
        updatedMass.language = Number.parseInt(event.target.value);
        setSpecialMass(updatedMass);
        break;
      case Controls.MassTime:
        updatedMass.time = event.target.value;
        setSpecialMass(updatedMass);
        break;
      case Controls.Notes:
        updatedMass.notes = event.target.value;
        setSpecialMass(updatedMass);
        break;
    }
  };

  const deleteMass = async () => {
    try {
      setError("");
      setDeleting(true);
      await axios.delete(`/api/mass/templates/special/${specialMass.id}`);
      history.push("/special-masses");
    } catch (err) {
      handleAxiosError(err, "Error deleting mass", setError);
    } finally {
      setDeleting(false);
    }
  };

  const saveMass = async () => {
    try {
      setError("");
      setSaving(true);
      await axios.post<SpecialMassTemplate>("/api/mass/templates/special", {
        id: specialMass.id,
        startDateTime: `${specialMass.date} ${specialMass.time}`,
        language: {
          id: specialMass.language,
        },
        notes: specialMass.notes,
      });
      history.push("/special-masses");
    } catch (err) {
      setError("Error saving special mass");
    } finally {
      setSaving(false);
    }
  };

  const canSave = (): boolean => {
    return (
      specialMass &&
      specialMass.date.length > 0 &&
      specialMass.time.length > 0 &&
      specialMass.language > 0
    );
  };

  return (
    <div>
      <h2>{`${
        params.id && params.id !== "0" ? "Edit" : "New"
      } Special Mass`}</h2>
      <Card>
        <Card.Body className="new-intention-card">
          {error && <Alert variant={"danger"}>{error}</Alert>}
          <Card.Title>Date</Card.Title>
          <hr />
          <Form.Group controlId="formFirstName">
            <Form.Label>Mass Date</Form.Label>
            <Row style={{ maxWidth: "800px" }}>
              <Col>
                <Form.Control
                  value={specialMass.date}
                  size="lg"
                  type="date"
                  name={Controls.MassDate}
                  onChange={(event) => onSelectValue(event)}
                />
              </Col>
            </Row>
          </Form.Group>
          <br />
          <Card.Title>Time & Language</Card.Title>
          <hr />
          <Form.Group controlId="formFirstName">
            <Row style={{ maxWidth: "800px" }}>
              <Col>
                <Form.Label>Mass Time</Form.Label>
              </Col>
              <Col>
                <Form.Label>Language</Form.Label>
              </Col>
            </Row>
            <Row
              style={{ maxWidth: "800px" }}
              className="new-special-mass-start-time-row"
            >
              <Col>
                <Form.Control
                  value={specialMass.time}
                  size="lg"
                  type="time"
                  name={Controls.MassTime}
                  onChange={(e) => onSelectValue(e)}
                />
              </Col>
              <Col>
                <Form.Control
                  value={specialMass.language}
                  onChange={(e) => onSelectValue(e)}
                  as="select"
                  size="lg"
                  style={{ maxWidth: "400px" }}
                  name={Controls.Language}
                >
                  {Languages.map((f) => (
                    <option value={f.value}>{f.name}</option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
          <br />
          <Card.Title>Notes</Card.Title>
          <hr />
          <Form.Group controlId="formLastName">
            <Row style={{ maxWidth: "800px" }}>
              <Col>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={specialMass.notes}
                  type="text"
                  name={Controls.Notes}
                  onChange={(e) => onSelectValue(e)}
                />
              </Col>
            </Row>
          </Form.Group>
          <div className="new-special-mass-button-group">
            <Button
              variant="outline-secondary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
            {specialMass.id > 0 && (
              <Button
                className="edit-special-mass-button"
                variant="danger"
                onClick={deleteMass}
                permissions={[Permissions.DeleteSpecialMass]}
              >
                {deleting ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Delete"
                )}
              </Button>
            )}

            <Button
              className="edit-special-mass-button"
              variant="success"
              onClick={saveMass}
              disabled={!canSave()}
              permissions={[
                Permissions.EditSpecialMass,
                Permissions.CreateSpecialMass,
              ]}
            >
              {saving ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default EditSpecialMass;
