import { useEffect } from "react";
import { Card, Table, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "vincent-types/models";
import { getActivityAsync } from "../../../redux/admin-slice";
import moment from "moment";

function ActivityPage() {
  const {
    error,
    data: activity,
    refreshed,
  } = useSelector((state: AppState) => state.admin.activity);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getActivityAsync());
  }, [dispatch]);
  return (
    <div>
      <Card>
        <Card.Body className="new-intention-card">
          <Card.Title>Activity</Card.Title>
          <hr />
          <div
            style={{
              display: "block",
              height: "400px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Activity</th>
                </tr>
              </thead>
              <tbody>
                {!refreshed && !error && (
                  <tr>
                    <td colSpan={2} align="center">
                      <Spinner animation="border" size="sm" />
                    </td>
                  </tr>
                )}
                {!error &&
                  activity &&
                  activity.map((a) => {
                    return (
                      <tr>
                        <td>
                          {moment(a.timestamp).format("MMM Do YYYY @ h:mm a")}
                        </td>
                        <td>{a.activity}</td>
                      </tr>
                    );
                  })}
                {error && (
                  <tr>
                    <td colSpan={2} align="center">
                      {error}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ActivityPage;
