import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import Calendar from "../calendar";
import { AppState } from "vincent-types/models";

interface CalendarModalProps {
  onSelectDate: (date: string) => void;
  onHide: () => void;
  date: string;
  show: boolean;
}

function CalendarModal(props: CalendarModalProps) {
  const [loading, setLoading] = useState(true);
  const [firstMonth, setFirstMonth] = useState(moment());
  const [secondMonth, setSecondMonth] = useState(moment());
  const [thirdMonth, setThirdMonth] = useState(moment());
  const dates = useSelector((state: AppState) => state.week.dates);

  useEffect(() => {
    if (dates) {
      const selectedMonth = moment(dates.start);
      const previousMonth = moment(selectedMonth);
      previousMonth.subtract(1, "month");
      const nextMonth = moment(selectedMonth);
      nextMonth.add(30, "day");
      setFirstMonth(previousMonth);
      setSecondMonth(selectedMonth);
      setThirdMonth(nextMonth);
      setLoading(false);
    }
  }, [dates]);

  const onClickBack = () => {
    setLoading(true);
    //   const newMonth = moment(firstMonth);
    setFirstMonth(firstMonth.clone().subtract(3, "months"));
    setSecondMonth(secondMonth.clone().subtract(3, "months"));
    setThirdMonth(thirdMonth.clone().subtract(3, "months"));
    setLoading(false);
  };

  const onClickNext = () => {
    setLoading(true);
    //   const newMonth = moment(firstMonth);
    setFirstMonth(firstMonth.clone().add(3, "months"));
    setSecondMonth(secondMonth.clone().add(3, "months"));
    setThirdMonth(thirdMonth.clone().add(3, "months"));
    setLoading(false);
  };

  const onSelectDate = (date: string) => {
    if (props.onSelectDate) {
      props.onSelectDate(date);
    }
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Week
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>
            <BsFillCaretLeftFill
              onClick={onClickBack}
              style={{ marginTop: "-5px" }}
            />
          </h1>
          {loading ? (
            <div>
              <Spinner
                animation="border"
                role="status"
                style={{
                  position: "absolute",
                  left: "48%",
                }}
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  visibility: loading ? "hidden" : "visible",
                }}
              >
                <Calendar date={firstMonth} onSelectDate={onSelectDate} />
              </div>
              <div
                style={{
                  marginRight: "10px",
                  visibility: loading ? "hidden" : "visible",
                }}
              >
                <Calendar date={secondMonth} onSelectDate={onSelectDate} />
              </div>
              <div style={{ visibility: loading ? "hidden" : "visible" }}>
                <Calendar date={thirdMonth} onSelectDate={onSelectDate} />
              </div>
            </div>
          )}

          <h1>
            <BsFillCaretRightFill
              onClick={onClickNext}
              style={{ marginTop: "-5px" }}
            />
          </h1>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CalendarModal;
