import { Fragment, useEffect, useState } from "react";
import { Card, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import MaskedInput from "react-maskedinput";
import { IntentionCart, Language } from "vincent-types/models";
import { LanguageObjects as Languages } from "vincent-types/constants";
import axios from "axios";
import { Highlighter, Typeahead } from "react-bootstrap-typeahead";
import { formatRequestorName } from "vincent-utils";
import RequestorFormatModal from "../../../modals/requestor-format-modal";

interface RequestorDetailsProps {
  cart: IntentionCart;
  onCancel: () => void;
  onNext: (requestor: {
    name: string;
    phone: string;
    languages: Language[];
  }) => void;
}

function RequestorDetails(props: RequestorDetailsProps) {
  const [name, setName] = useState(props.cart.requestor.name);
  const [phone, setPhone] = useState(props.cart.requestor.phone);
  const [languages, setLanguages] = useState(props.cart.requestor.languages);
  const [requestorsLoaded, setRequestorsLoaded] = useState(false);
  const [requestors, setRequestors] = useState([]);
  const [showRequestorFormatModal, setShowRequestorFormatModal] =
    useState(false);
  const [selection, setSelection] = useState<
    {
      name: string;
    }[]
  >([]);

  useEffect(() => {
    setRequestorsLoaded(false);
    const getRequestors = async () => {
      const results = await axios.get("/api/requestors");
      setRequestorsLoaded(true);
      setRequestors(
        results.data.sort((a: any, b: any) => a.name.localeCompare(b.name))
      );
    };
    getRequestors();
  }, []);

  const selectLanguage = (event: any) => {
    const selectedLanguage = Languages.find(
      (l) => l.id === Number.parseInt(event.target.value)
    );

    if (selectedLanguage) {
      if (event.target.checked) {
        if (!languages.some((l) => l.id === event.target.value)) {
          setLanguages([...languages, selectedLanguage]);
        }
      } else {
        const newLanguages = languages.filter(
          (l) => l.id !== Number.parseInt(event.target.value)
        );
        setLanguages(newLanguages);
      }
    }
  };

  const save = (force?: boolean) => {
    if (!force && name) {
      const formattedInput = formatRequestorName(name);
      if (formattedInput !== name) {
        setShowRequestorFormatModal(true);
        return;
      }
    }
    props.onNext &&
      props.onNext({
        name: formatRequestorName(name),
        phone,
        languages,
      });
  };

  const notComplete = () => {
    return languages.length === 0;
  };

  const renderRequestorOptions = (option: any, { text }: { text: string }) => (
    <Fragment>
      <Highlighter search={text}>{option.name}</Highlighter>
      <div>
        <small>{option.phone}</small>
      </div>
    </Fragment>
  );

  const handleRequestorInput = (text: string, event: any) => {
    setSelection([]);
    setName(text);
  };

  const setSelectedRequestor = (selection: any) => {
    if (selection && selection.length > 0) {
      setName(selection[0].name);
      setPhone(selection[0].phone);
      setSelection(selection);
    }
  };

  return (
    <Card>
      <Card.Body className="new-intention-card">
        <Card.Title>Requestor Details</Card.Title>
        <hr />
        {!requestorsLoaded ? (
          <Spinner animation="border" />
        ) : (
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Requestor Name</Form.Label>
                  <Typeahead
                    renderMenuItemChildren={renderRequestorOptions}
                    id="requestor-name"
                    labelKey="name"
                    onChange={setSelectedRequestor}
                    onInputChange={handleRequestorInput}
                    options={requestors}
                    placeholder=""
                    selected={selection}
                    defaultOpen={false}
                    defaultInputValue={props.cart.requestor.name}
                  />
                  {/* <Form.Control
                    value={name}
                    type="text"
                    onChange={(event) => setName(event.target.value)}
                  /> */}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formPhoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    as={MaskedInput}
                    mask="(111) 111-1111"
                    value={phone}
                    type="phone"
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="formPreferredLanguages">
              <Form.Label>Preferred Mass Languages</Form.Label>
              <Form.Group controlId="formBasicCheckbox">
                {Languages.map((l) => {
                  return (
                    <Form.Check
                      value={l.id}
                      type="checkbox"
                      label={l.name}
                      onChange={selectLanguage}
                      checked={languages.some((lang) => lang.id === l.id)}
                    />
                  );
                })}
              </Form.Group>
            </Form.Group>
          </Form>
        )}
      </Card.Body>
      <div className="new-intention-button-group">
        <Button variant="light" onClick={props.onCancel}>
          Cancel
        </Button>
        <div className="new-intention-button-spacer" />
        <Button
          variant="success"
          onClick={() => save(false)}
          disabled={notComplete()}
        >
          Next
        </Button>
      </div>
      <RequestorFormatModal
        onContinue={() => save(true)}
        onHide={() => setShowRequestorFormatModal(false)}
        onEdit={() => setShowRequestorFormatModal(false)}
        show={showRequestorFormatModal}
        original={name}
        modified={formatRequestorName(name)}
      />
    </Card>
  );
}

export default RequestorDetails;
