import { Priest } from "vincent-types/models";
import moment, { Moment } from "moment";

let today: Moment | undefined = undefined;

export const priestIsActive = (p: Priest, date: Moment | string): boolean => {
  const start = moment(p.startDate);
  const end = p.endDate ? moment(p.endDate) : undefined;
  const myDate = moment(date);
  if (!today) {
    today = moment();
  }
  return (
    start.isSameOrBefore(myDate, "day") &&
    (!end || end.isSameOrAfter(myDate, "day"))
  );
};
