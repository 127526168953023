import { ButtonProps, Button } from "react-bootstrap";
import { AppState } from "vincent-types/models";
import { useSelector } from "react-redux";

interface PermissionBasedButtonProps {
  permissions?: string[];
}

function PermissionBasedButton(
  props: PermissionBasedButtonProps & ButtonProps
) {
  const { permissions } = props;
  const userPermissions = useSelector(
    (state: AppState) => state.user.permissions
  );
  if (permissions && !userPermissions.some((u) => permissions.includes(u))) {
    return null;
  }
  return <Button {...props}>{props.children}</Button>;
}

export default PermissionBasedButton;
