import React from "react";
import "./styles.css";
import { Table } from "react-bootstrap";
import { formatDateTime } from "../../../utils/stringFunctions";

interface IntentionsWithoutPresiderPrintoutProps {
  report: {
    printDateTime?: string;
    intentions: {
      subject: string;
      startDateTime: string;
    }[];
  };
}

export class IntentionsWithoutPresiderPrintout extends React.PureComponent<IntentionsWithoutPresiderPrintoutProps> {
  render() {
    const { report } = this.props;
    return (
      <div className="intentions-without-presider-print-report-container">
        <h2>Intentions Without Presiders</h2>
        <hr />
        <h2>Report Date & Time</h2>
        <h3>{report.printDateTime}</h3>
        <hr />
        <div className="intentions-without-presider-print-table-container">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Intention</th>
                <th>{`Mass Date & Time`}</th>
              </tr>
            </thead>
            <tbody>
              {report.intentions.map((i) => (
                <tr>
                  <td>{i.subject}</td>
                  <td>{formatDateTime(i.startDateTime)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
