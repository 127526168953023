import { Modal, Button } from "react-bootstrap";

export interface RequestorFormatModalProps {
  onContinue: () => void;
  onEdit: () => void;
  onHide: () => void;
  show: boolean;
  original: string;
  modified: string;
}

function RequestorFormatModal(props: RequestorFormatModalProps) {
  return (
    <Modal {...props} show={props.show} size="lg" centered>
      <Modal.Header closeButton>
        <h3>Formatting Applied</h3>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          The requestor's name will be modified.
          <br />
          <br />
          Original:
          <div
            style={{
              border: "1px solid black",
              textAlign: "center",
              marginTop: "20px",
              backgroundColor: "lightgray",
            }}
          >
            "{props.original}"
          </div>
          <br />
          New:
          <div
            style={{
              border: "1px solid black",
              textAlign: "center",
              marginTop: "20px",
              backgroundColor: "lightgray",
            }}
          >
            "{props.modified}"
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onEdit}>Edit</Button>
        <Button onClick={props.onContinue}>Continue</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RequestorFormatModal;
