import { useState, useRef } from "react";
import { Card, Form, Row, Col, Button, Table } from "react-bootstrap";
import { IntentionsByPresiderPrintout } from "../../../components/print-components/intentions-by-presider";
import { formatDateTime } from "../../../utils/stringFunctions";
import { Spinner } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { AppState, Priest, Mass } from "vincent-types/models";
import moment from "moment";
import "./styles.css";
import { useSelector } from "react-redux";
import axios from "axios";

interface IntentionReportEntry {
  startDateTime: string;
  subject: string;
  price: string;
}

interface IntentionsTableProps {
  intentions: IntentionReportEntry[];
  error?: string;
  height?: string;
}

function IntentionsTable(props: IntentionsTableProps) {
  const { intentions, error } = props;
  return (
    <div
      className="intentions-by-presider-table-container"
      style={{ maxHeight: props.height || "400px" }}
    >
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Intention</th>
            <th>{`Mass Date & Time`}</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {!error && intentions.length === 0 && (
            <tr>
              <td colSpan={3} align="center">
                No Intentions Found
              </td>
            </tr>
          )}
          {!error &&
            intentions.map((i) => (
              <tr>
                <td>{i.subject}</td>
                <td>{formatDateTime(i.startDateTime)}</td>
                <td>{`$${i.price}`}</td>
              </tr>
            ))}
          {error && (
            <tr>
              <td colSpan={3} align="center">
                {error}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

function IntentionsByPresider() {
  const priests = useSelector((state: AppState) => state.priests.data);
  const [intentions, setIntentions] = useState<IntentionReportEntry[]>([]);
  const [selectedPriest, setSelectedPriest] = useState<Priest | undefined>(
    undefined
  );
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [report, setReport] = useState<{
    intentions: IntentionReportEntry[];
    total: string;
    printDateTime?: string;
    presider?: string;
  }>({
    intentions: [],
    total: "" + 0,
  });
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const selectPriest = (id: number) => {
    setSelectedPriest(priests.find((p) => p.id === id));
  };

  const search = async () => {
    setIntentions([]);
    setLoading(true);
    setLoaded(false);
    setError("");
    try {
      const resp = await axios.get(
        `/api/mass/instances?start=${startDate}&end=${endDate}`
      );
      const masses = resp.data as Mass[];
      const myIntentions: IntentionReportEntry[] = masses
        .filter((m: Mass) => m.intention?.priest?.id === selectedPriest?.id)
        .map((m: Mass) => {
          return {
            startDateTime: m.startDateTime,
            subject: m.intention?.subject || "",
            price:
              m.intention?.payment === undefined
                ? "10.00"
                : m.intention?.payment,
          };
        });
      setIntentions(myIntentions);
      setReport({
        printDateTime: moment().format("dddd, MMM Do YYYY h:mma"),
        presider: `${selectedPriest?.title} ${selectedPriest?.firstName}`,
        total: "" + myIntentions.length * 10,
        intentions: myIntentions || [],
      });
    } catch (err) {
      setError(err?.response?.data?.error || "Error retrieving information");
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  return (
    <div>
      <h2>Reports</h2>
      <Card>
        <Card.Body className="new-intention-card">
          <Card.Title>Intentions by Presider</Card.Title>
          <hr />
          <Form>
            <Row>
              <Col md={8}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Presider</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedPriest?.id || -1}
                    onChange={(event) => {
                      selectPriest(Number(event.target.value));
                    }}
                  >
                    <option value={-1}>Select</option>
                    {priests.map((p: Priest) => {
                      return (
                        <option
                          value={Number(p.id)}
                        >{`${p.title} ${p.firstName}`}</option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    value={startDate}
                    type="date"
                    onChange={(event) => {
                      setStartDate(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formLastName">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    value={endDate}
                    type="date"
                    onChange={(event) => {
                      setEndDate(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="intentions-by-presider-button-group">
            <Button
              disabled={
                !selectedPriest ||
                selectedPriest?.id === -1 ||
                !startDate ||
                !endDate
              }
              onClick={search}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Search"}
            </Button>
            <Button
              style={{
                visibility: intentions.length > 0 ? "visible" : "hidden",
              }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </div>
          {loaded && (
            <div>
              <IntentionsTable intentions={intentions} error={error} />
              <div className="new-intention-payment-summary-container">
                <Table size="sm" style={{ width: "200px" }} borderless>
                  <tr>
                    <td
                      align="right"
                      style={{ width: "60%", verticalAlign: "middle" }}
                    >
                      Total:
                    </td>
                    <td align="right">
                      <span className="new-intention-money">
                        {Number(
                          intentions
                            .map((i) => Number.parseInt(i.price))
                            .reduce((a, b) => a + b, 0)
                        )
                          .toFixed(2)
                          .toLocaleString()}
                      </span>
                    </td>
                  </tr>
                </Table>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      <div style={{ display: "none" }}>
        <IntentionsByPresiderPrintout ref={componentRef} report={report} />
      </div>
    </div>
  );
}

export default IntentionsByPresider;
