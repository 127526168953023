import React from "react";
import "./styles.css";
import { Table } from "react-bootstrap";
import { RequestedIntention } from "vincent-types/models";
import { formatDateTime } from "../../../utils/stringFunctions";
import moment from "moment";
import { formatValue } from "react-currency-input-field";

interface IntentionsReceiptPrintoutProps {
  requestor: string;
  total: number;
  paid: string;
  intentions: RequestedIntention[];
}

export class IntentionsReceiptPrintout extends React.PureComponent<IntentionsReceiptPrintoutProps> {
  render() {
    const { requestor, total, paid, intentions } = this.props;
    return (
      <div className="intentions-by-presider-report-container">
        <h2>Receipt Date & Time</h2>
        <h3>{formatDateTime(moment())}</h3>
        <hr />
        <h2>Requestor</h2>
        <h3>{requestor}</h3>
        <hr />
        <h2>Total Due</h2>
        <h3>
          {" "}
          <span className="new-intention-money">
            {Number(total).toFixed(2).toLocaleString()}
          </span>
        </h3>
        <hr />
        <h2>Total Paid</h2>
        <h3>
          {formatValue({
            value: "" + paid,
            groupSeparator: ",",
            decimalSeparator: ".",
            prefix: "$",
          })}
        </h3>
        <hr />
        <div style={{ marginTop: "20px" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Intention</th>
                <th>{`Mass Date & Time`}</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {intentions.map((i) => (
                <tr>
                  <td>{i.subject}</td>
                  <td>{formatDateTime(i.dateTime)}</td>
                  <td>{`$${i.price}`}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
