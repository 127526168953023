import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UnavailableDate, UnavailableDateState } from "vincent-types/models";
import axios from "axios";

const INITIAL_STATE: UnavailableDateState = {
  data: [],
};

export const getUnavailableDatesAsync = createAsyncThunk(
  "unavailableDates/getUnavailableDatesAsync",
  async () => {
    const resp = await axios.get("/api/priests/unavailable-dates");
    return resp.data;
  }
);

export const unavailableDateSlice = createSlice({
  name: "masses",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getUnavailableDatesAsync.fulfilled.toString()]: (state, action) => {
      state.data = action.payload as UnavailableDate[];
    },
    [getUnavailableDatesAsync.rejected.toString()]: (state, action) => {
      state.error =
        action.payload?.err?.message || "Error retrieving unavailable dates";
    },
    [getUnavailableDatesAsync.pending.toString()]: (state, action) => {
      state.refreshed = undefined;
      state.error = undefined;
      state.data = [];
    },
  },
});

// export const { logout, setPreference } = massSlice.actions;

export default unavailableDateSlice.reducer;
