import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import axios from "axios";
import { WeekState, Intention } from "vincent-types/models";
import { groupMassesByDay } from "../utils/massFunctions";

const INITIAL_STATE: WeekState = {
  masses: {},
};

export const getMassesAsync = createAsyncThunk(
  "week/getMassesAsync",
  async (dates: { start: string; end: string }) => {
    const myStart = moment(dates.start).format("YYYY-MM-DD");
    const myEnd = moment(dates.end).format("YYYY-MM-DD");
    const resp = await axios.get(
      `/api/mass/instances?start=${myStart}&end=${myEnd}`
    );
    const massesByDay = groupMassesByDay(resp.data);
    return {
      masses: massesByDay,
    };
  }
);

export const updateIntentionAsync = createAsyncThunk(
  "masses/updateIntentionAsync",
  async (update: Intention) => {
    let resp;
    if (update.id > 0) {
      resp = await axios.put(`/api/intentions`, update);
    } else {
      resp = await axios.post("/api/intentions", [update]);
    }

    return resp.data;
  }
);

export const massSlice = createSlice({
  name: "week",
  initialState: INITIAL_STATE,
  reducers: {
    selectDate: (state, action) => {
      const start = moment(action.payload).startOf("week");
      const end = moment(start);
      end.add(6, "days");
      let myDate = moment(start);
      const days = [];
      while (myDate.diff(end, "days") <= 0) {
        days.push(moment(myDate).toString());
        myDate = myDate.add(1, "days");
      }
      state.dates = {
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
        days,
      };
      state.masses = {};
    },
  },
  extraReducers: {
    [getMassesAsync.pending.toString()]: (state, action) => {
      state.refreshed = undefined;
      state.error = "";
    },
    [getMassesAsync.fulfilled.toString()]: (state, action) => {
      state.refreshed = moment().format();
      state.masses = action.payload.masses;
    },
    [getMassesAsync.rejected.toString()]: (state, action) => {
      state.error = "Error retrieving intentions";
    },
    [updateIntentionAsync.pending.toString()]: (state, action) => {},
    [updateIntentionAsync.fulfilled.toString()]: (state, action) => {},
    [updateIntentionAsync.rejected.toString()]: (state, action) => {
      console.log("GELO => Update Mass Failed: ", action);
    },
  },
});

export const { selectDate } = massSlice.actions;

export default massSlice.reducer;
