import { Card, Form, Row, Col, Spinner, Alert } from "react-bootstrap";
import { Priest, AppState } from "vincent-types/models";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useParams, useHistory } from "react-router";
import { Permissions } from "vincent-types/enums";
import Button from "../../../../components/permission-based-button";
import _ from "lodash";
import axios from "axios";
import { handleAxiosError } from "../../../../utils/network";

const INITIAL_PRIEST: Priest = {
  id: 0,
  title: "",
  firstName: "",
  lastName: "",
  startDate: "",
  endDate: "",
};

enum Controls {
  Title = "Title",
  FirstName = "FirstName",
  LastName = "LastName",
  StartDate = "StartDate",
  EndDate = "EndDate",
}

const TITLES = ["Select", "Bp", "Fr", "Msgr"];

function EditPriest() {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");

  const buildPriest = (priests: Priest[]) => {
    if (!priests) {
      return INITIAL_PRIEST;
    }
    return (
      priests.find((p) => p.id === Number.parseInt(params.id || "-1")) ||
      INITIAL_PRIEST
    );
  };
  const [priest, setPriest] = useState(
    buildPriest(useSelector((state: AppState) => state.priests.data))
  );

  const title = () => {
    if (priest.id > 0) {
      return `Edit ${priest.title} ${priest.firstName}`;
    }
    return `New Priest`;
  };

  const canSave = () => {
    return (
      priest &&
      priest.title &&
      priest.title !== "Select" &&
      priest.firstName.length > 0 &&
      priest.lastName.length > 0 &&
      priest.startDate &&
      priest.startDate.length > 0
    );
  };

  const onSelectValue = (event: any) => {
    const updatedPriest = _.cloneDeep(priest);
    switch (event.target.name) {
      case Controls.Title:
        updatedPriest.title = event.target.value;
        break;
      case Controls.FirstName:
        updatedPriest.firstName = event.target.value;
        break;
      case Controls.LastName:
        updatedPriest.lastName = event.target.value;
        break;
      case Controls.StartDate:
        updatedPriest.startDate = event.target.value;
        break;
      case Controls.EndDate:
        updatedPriest.endDate = event.target.value;
        break;
    }
    setPriest(updatedPriest);
  };

  const save = async () => {
    try {
      setError("");
      setSaving(true);
      await axios.post("/api/priests", priest);
      history.goBack();
    } catch (err) {
      handleAxiosError(err, "Error updating priest information", setError);
    } finally {
      setSaving(false);
    }
  };
  return (
    <div>
      <h2>{title()}</h2>
      <Card>
        <Card.Body className="new-intention-card">
          {error && <Alert variant={"danger"}>{error}</Alert>}
          <Form>
            <Card.Title>Name</Card.Title>
            <hr />
            <Row md={3}>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    value={priest.title}
                    onChange={onSelectValue}
                    as="select"
                    name={Controls.Title}
                  >
                    {TITLES.map((t) => (
                      <option value={t}>{t}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row md={3}>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    value={priest.firstName}
                    type="text"
                    onChange={onSelectValue}
                    name={Controls.FirstName}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    value={priest.lastName}
                    type="text"
                    onChange={onSelectValue}
                    name={Controls.LastName}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Card.Title>Effective Dates</Card.Title>
            <hr />
            <Row md={3}>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    value={priest.startDate}
                    type="date"
                    onChange={onSelectValue}
                    name={Controls.StartDate}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formLastName">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    value={priest.endDate}
                    type="date"
                    onChange={onSelectValue}
                    name={Controls.EndDate}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="new-recurring-mass-button-group">
            <Button
              variant="outline-secondary"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={save}
              disabled={!canSave()}
              permissions={[Permissions.CreatePriest, Permissions.EditPriest]}
            >
              {saving ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default EditPriest;
