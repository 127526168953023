import { useState, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import IntentionCard from "../../../components/intention-card";
import { Mass, RequestedIntention, Language } from "vincent-types/models";
import { formatMassTime } from "../../../utils/stringFunctions";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import axios from "axios";
import _ from "lodash";

interface IntentionDetailsProps {
  languages: Language[];
  intentions: RequestedIntention[];
  onCancel: () => void;
  onNext: (intentions: RequestedIntention[]) => void;
  onBack: () => void;
}

function IntentionDetails(props: IntentionDetailsProps) {
  const [availableMasses, setAvailableMasses] = useState<Mass[]>([]);
  const [maxDate, setMaxDate] = useState(moment().format("YYYY-MM-DD"));
  // const [availableMassesError, setAvailableMassesError] = useState();
  const [subject, setSubject] = useState("");
  const [noCharge, setNoCharge] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showNoCharge, setShowNoCharge] = useState(false);
  const [selectedMasses, setSelectedMasses] = useState<Mass[]>([]);
  const [intentions, setIntentions] = useState<RequestedIntention[]>(
    props.intentions
  );
  const [deceased, setDeceased] = useState(false);

  const isAddEnabled = () => {
    return subject && selectedMasses.length > 0;
  };

  const selectMass = (event: any) => {
    const selectedMassTime = event.target.value;
    if (event.target.checked) {
      if (!selectedMasses.find((s) => s.startDateTime === selectedMassTime)) {
        const selectedMass = availableMasses.find(
          (s) => s.startDateTime === selectedMassTime
        );
        if (selectedMass) {
          setSelectedMasses([...selectedMasses, selectedMass]);
        }
      }
    } else {
      const newSelectedMasses = selectedMasses.filter(
        (l) => l.startDateTime !== selectedMassTime
      );
      setSelectedMasses(newSelectedMasses);
    }
  };

  const deleteIntention = (intention: RequestedIntention) => {
    let newAvailableMasses = [...availableMasses, intention.mass] || [];
    newAvailableMasses.sort((a, b) =>
      a.startDateTime.localeCompare(b.startDateTime)
    );
    setAvailableMasses(newAvailableMasses);
    setIntentions(intentions.filter((i) => i.dateTime !== intention.dateTime));
  };

  const handleSubjectChange = (event: any) => {
    const { value } = event.target;
    if ((value as string).toLowerCase() === "parishioners") {
      setShowNoCharge(true);
      setNoCharge(true);
    } else {
      setShowNoCharge(false);
      setNoCharge(false);
    }
    setSubject(value);
  };

  const add = () => {
    const newIntentions: RequestedIntention[] = selectedMasses.map((s) => {
      return {
        massId: s.id,
        subject,
        dateTime: s.startDateTime,
        price: noCharge ? "0.00" : "10.00",
        deceased,
        mass: s,
      };
    });
    setAvailableMasses(
      availableMasses.filter(
        (a) => !selectedMasses.some((s) => s.startDateTime === a.startDateTime)
      )
    );
    setIntentions([...intentions, ...newIntentions]);
    setSubject("");
    setDeceased(false);
    setSelectedMasses([]);
  };
  const getInstances = async () => {
    const start = moment(maxDate).format("YYYY-MM-DD");
    const limit = 100;
    try {
      const response = await axios.get(
        `/api/mass/instances?start=${start}&limit=${limit}&status=open&languages=${props.languages
          .map((l) => l.id)
          .join(",")}`
      );
      const masses = response.data as Mass[];
      if (masses.length === 0) {
        setHasMore(false);
      }
      let newMasses = _.cloneDeep(availableMasses);
      newMasses = newMasses.concat(masses);
      setAvailableMasses(newMasses.filter((m) => !m.priestsUnavailable));
      const newMaxDate = moment(masses[masses.length - 1].startDateTime)
        .add(1, "days")
        .format("YYYY-MM-DD");
      setMaxDate(newMaxDate);
    } catch (err) {
      // setAvailableMassesError(err);
    }
  };
  useEffect(() => {
    getInstances();
  }, []);
  return (
    <div className="new-intention-intention-details-container">
      <Card className="new-intention-card-half" style={{ marginRight: "5px" }}>
        <Card.Body className="new-intention-card">
          <Card.Title>Intention Details</Card.Title>
          <hr />
          <Form>
            <Form.Group controlId="formIntention">
              <Form.Label>Intention</Form.Label>
              <Form.Control
                value={subject}
                type="text"
                onChange={handleSubjectChange}
              />
            </Form.Group>
          </Form>
          <Form.Group controlId="formDeceased">
            <Form.Check
              type="checkbox"
              label="Deceased"
              onChange={(event) => setDeceased(event.target.checked)}
              checked={deceased}
            />
          </Form.Group>
          {showNoCharge && (
            <Form.Group controlId="formNoCharge">
              <Form.Check
                type="checkbox"
                label="No Charge"
                onChange={(event) => setNoCharge(event.target.checked)}
                checked={noCharge}
              />
            </Form.Group>
          )}
          <Form.Group controlId="formAvailableMasses">
            <Form.Label>Mass</Form.Label>
            <div
              id="scrollableDiv"
              className="new-intention-available-mass-list-container"
            >
              {!hasMore && availableMasses.length === 0 ? (
                <div>No masses found</div>
              ) : (
                <InfiniteScroll
                  dataLength={availableMasses.length}
                  next={getInstances}
                  hasMore={hasMore}
                  loader={<span>Loading...</span>}
                  scrollableTarget="scrollableDiv"
                >
                  {availableMasses.map((a) => (
                    <Form.Check
                      type="checkbox"
                      value={a.startDateTime}
                      onChange={selectMass}
                      label={formatMassTime(a)}
                      checked={selectedMasses
                        .map((s) => s.startDateTime)
                        .includes(a.startDateTime)}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </Form.Group>
        </Card.Body>
        <div className="new-intention-button-group">
          <Button disabled={!isAddEnabled()} variant="success" onClick={add}>
            Add
          </Button>
        </div>
      </Card>
      <Card className="new-intention-card-half">
        <Card.Body className="new-intention-card">
          <Card.Title>{`Requested Intentions & Dates (${intentions.length})`}</Card.Title>
          <hr />
          <div className="new-intention-intentions-list">
            {intentions.map((i) => (
              <IntentionCard intention={i} onDelete={deleteIntention} />
            ))}
          </div>
        </Card.Body>
        <div className="new-intention-button-group">
          <Button variant="outline-secondary" onClick={props.onCancel}>
            Cancel
          </Button>
          <div className="new-intention-button-spacer" />
          <Button variant="outline-primary" onClick={props.onBack}>
            Back
          </Button>
          <div className="new-intention-button-spacer" />
          <Button
            disabled={intentions.length === 0}
            variant="success"
            onClick={() => props.onNext(intentions)}
          >
            Next
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default IntentionDetails;
