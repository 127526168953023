import React from "react";
import moment from "moment";
import { Mass, MassesByDay } from "vincent-types/models";
import { vincentFormat } from "../../../utils/stringFunctions";
import { CgCross } from "react-icons/cg";
import "./styles.css";

interface BulletinInsertPrintoutProps {
  masses: MassesByDay;
}

export class BulletinInsertPrintout extends React.PureComponent<BulletinInsertPrintoutProps> {
  render() {
    const { masses } = this.props as BulletinInsertPrintoutProps;

    return (
      <div className="bulletin-printout-report-container">
        <span className="bulletin-printout-report-title">MASS INTENTIONS</span>
        <br />
        <hr className="bulletin-printout-report-divider" />
        <span className="bulletin-printout-report-title">
          INTENCIONES DE MISAS
        </span>
        <br />
        <div className="bulletin-printout-report-days-container">
          {Object.keys(masses).map((k) => {
            const massesForDay = masses[k];
            return (
              <>
                <span className="bulletin-printout-report-day-header">
                  {moment(k).format("MM/DD")}
                </span>
                <div className="bulletin-printout-report-intention-container">
                  {massesForDay.map((m: Mass) => {
                    return (
                      <span>
                        {`${vincentFormat(m.startDateTime, "h:mm a")} ${
                          m.intention ? m.intention.subject : "---"
                        }`}

                        {m.intention?.deceased && (
                          <CgCross size="18px" style={{ marginTop: "-5px" }} />
                        )}
                      </span>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  }
}
