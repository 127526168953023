import { Nav, Navbar, Dropdown, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconContext } from "react-icons";
import { AppState } from "vincent-types/models";
import { Permissions } from "vincent-types/enums";
import "./styles.css";

interface NavBarProps {
  logout: () => void;
}

function NavBar(props: NavBarProps) {
  const user = useSelector((state: AppState) => state.user);
  const logout = () => {
    if (props.logout) {
      props.logout();
    }
  };
  return (
    <IconContext.Provider value={{ className: "bell" }}>
      <Navbar
        fixed="top"
        className="navbar navbar-expand-lg navbar-dark bg-primary"
      >
        <Navbar.Brand href="#home">St Vincent Intentions</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>
          {user.permissions.some((p) =>
            [
              Permissions.ViewRecurringMass.toString(),
              Permissions.ViewSpecialMass.toString(),
            ].includes(p)
          ) && (
            <NavDropdown title="Masses" id="masses-dropdown">
              {user.permissions.includes(Permissions.ViewRecurringMass) && (
                <NavDropdown.Item as={Link} to="/recurring-masses">
                  Recurring
                </NavDropdown.Item>
              )}
              {user.permissions.includes(Permissions.ViewSpecialMass) && (
                <NavDropdown.Item as={Link} to="/special-masses">
                  Special
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )}
          {user.permissions.includes(Permissions.ViewPriest) && (
            <NavDropdown title="Priests" id="priests-dropdown">
              <NavDropdown.Item as={Link} to="/priests">
                Roster
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/unavailable-dates">
                Unavailable Dates
              </NavDropdown.Item>
            </NavDropdown>
          )}
          {user.permissions.includes(Permissions.RunReports) && (
            <NavDropdown title="Reports" id="reports-dropdown">
              <NavDropdown.Item as={Link} to="/intentions-by-presider">
                Intentions By Presider
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/intentions-by-requestor">
                Intentions By Requestor
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/intentions-by-request-date">
                Intentions By Request Date
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/intentions-without-presider">
                Intentions Without Presider
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/bulletin-printout">
                Bulletin Printout
              </NavDropdown.Item>
            </NavDropdown>
          )}
          {user.permissions.includes(Permissions.ViewUser) && (
            <NavDropdown title="Admin" id="admin-dropdown">
              <NavDropdown.Item as={Link} to="/admin/users">
                Users
              </NavDropdown.Item>
              {user.permissions.includes(Permissions.ViewUserActivity) && (
                <NavDropdown.Item as={Link} to="/admin/users/activity">
                  Activity
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )}
        </Nav>
        <Nav>
          <NavDropdown alignRight id="user-dropdown" title={user.username}>
            <Dropdown.Item eventKey="2" onClick={logout}>
              Log Out
            </Dropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
    </IconContext.Provider>
  );
}

export default NavBar;
