import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  HomeLayoutOptions,
  LoginStatuses,
  Preferences,
} from "vincent-types/enums";
import { User, UserState } from "vincent-types/models";
import axios from "axios";

const INITIAL_STATE: UserState = {
  username: "",
  firstName: "",
  lastName: "",
  token: "",
  active: false,
  roles: [],
  preferences: {
    homeLayout: HomeLayoutOptions.Portrait,
  },
  login: {
    status: LoginStatuses.LoggedOut,
    passwordResetRequired: false,
  },
  permissions: [],
};

// const parseError = (resp: Response): string => {
//   let error = null;
//   switch (resp.status) {
//     case 404:
//     case 400:
//       error = "Invalid username/password combination.  Please try again.";
//       break;
//     default:
//       error = "An error occurred during authentication. Please try again.";
//       break;
//   }
//   return error;
// };

export const loginAsync = createAsyncThunk(
  "user/loginAsync",
  async (
    creds: { username: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await axios.post("/api/users/authenticate", creds);
      return resp.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response?.data);
      }
      return null;
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "user/resetPasswordAsync",
  async (
    creds: { username: string; oldPassword: string; newPassword: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await axios.post("/api/users/reset", creds);
      return resp.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response?.data);
      }
      return null;
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    logout: (state) => {
      return INITIAL_STATE;
    },
    setPasswordResetError: (state, action) => {
      state.login.error = action.payload || "Error resetting password";
    },
    setPreference: (state, action) => {
      switch (action.payload.name) {
        case Preferences.HomeLayout:
          state.preferences.homeLayout = action.payload.value;
      }
    },
  },
  extraReducers: {
    [loginAsync.fulfilled.toString()]: (state, action) => {
      const payload = action.payload.user as User;
      state.username = payload.username;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.active = payload.active;
      state.roles = payload.roles;
      state.permissions = payload.permissions;
      state.token = action.payload.token;
      state.login.status = LoginStatuses.LoggedIn;
    },
    [loginAsync.rejected.toString()]: (state, action) => {
      const status = action?.payload.code;
      const resetRequired = action?.payload.passwordResetRequired;
      state.login.status = LoginStatuses.LoggedOut;
      if (status === 400 && resetRequired) {
        state.login.passwordResetRequired = true;
      } else if (status === 400 || status === 404) {
        state.login.error = "Invalid username/password combination";
      } else {
        state.login.error = "Error logging in";
      }
    },
    [loginAsync.pending.toString()]: (state, action) => {
      state.login.status = LoginStatuses.LoggingIn;
      state.login.error = undefined;
    },
    [resetPasswordAsync.pending.toString()]: (state, action) => {
      state.login.status = LoginStatuses.LoggingIn;
      state.login.error = undefined;
    },
    [resetPasswordAsync.fulfilled.toString()]: (state, action) => {
      state.login.passwordResetRequired = false;
      state.login.error = "Please log in again with new password";
      state.login.status = LoginStatuses.LoggedOut;
    },
    [resetPasswordAsync.rejected.toString()]: (state, action) => {
      const status = action?.payload.code;
      if (status === 404 || status === 400) {
        state.login.error = "Invalid password";
      } else {
        state.login.error = "Error resetting password";
      }
      state.login.status = LoginStatuses.LoggedOut;
    },
  },
});

export const { logout, setPreference, setPasswordResetError } =
  userSlice.actions;

export default userSlice.reducer;
