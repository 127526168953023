import { Mass, MassesByDay, MassTime } from "vincent-types/models";
import moment, { Moment } from "moment";

export const groupMassesByDay = (masses: Mass[]): MassesByDay => {
  let massesByDay: MassesByDay = {};
  masses.forEach((m: Mass) => {
    const day = moment(m.startDateTime).format("YYYY-MM-DD");
    if (!massesByDay[day]) {
      massesByDay[day] = [];
    }
    massesByDay[day].push(m);
  });
  return massesByDay;
};

export const findMostEffectiveStartTime = (
  times: MassTime[],
  day: Moment
): string => {
  const sortedTimes = [...times].sort((a, b) =>
    b.effectiveDate.localeCompare(a.effectiveDate)
  );
  let mostEffectiveTime = sortedTimes.find(
    (s) => s.effectiveDate.localeCompare(day.format("YYYY-MM-DD")) <= 0
  );
  if (!mostEffectiveTime) {
    mostEffectiveTime = sortedTimes.length > 0 ? sortedTimes[0] : undefined;
  }
  return mostEffectiveTime
    ? moment(mostEffectiveTime.start, "hh:mm:ss").format("HH:mm a")
    : "";
};
