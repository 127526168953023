import { useState, useRef } from "react";
import { Card, Form, Row, Col, Button, Spinner, Table } from "react-bootstrap";
import { formatDateTime } from "../../../utils/stringFunctions";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import axios from "axios";
import { BulletinInsertPrintout } from "../../../components/print-components/bulletin-insert";
import { groupMassesByDay } from "../../../utils/massFunctions";
import { Mass } from "vincent-types/models";

function IntentionsTable(props: {
  masses: Mass[];
  error: string;
  height?: string;
}) {
  const { masses, error } = props;
  return (
    <div
      className="intentions-by-presider-table-container"
      style={{ maxHeight: props.height || "400px" }}
    >
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Intention</th>
            <th>{`Mass Date & Time`}</th>
          </tr>
        </thead>
        <tbody>
          {!error && masses.length === 0 && (
            <tr>
              <td colSpan={3} align="center">
                No Intentions Found
              </td>
            </tr>
          )}
          {!error &&
            masses.map((m: Mass) => {
              return (
                <>
                  {" "}
                  {m.intention && (
                    <tr>
                      <td>{m.intention?.subject}</td>
                      <td>{formatDateTime(m.startDateTime)}</td>
                    </tr>
                  )}
                </>
              );
            })}
          {error && (
            <tr>
              <td colSpan={2} align="center">
                {error}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

function BulletinPrintout() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [masses, setMasses] = useState([]);
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const chooseStartDate = (event: any) => {
    const myMonday = moment(event.target.value)
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    const mySaturday = moment(event.target.value)
      .startOf("isoWeek")
      .add(6, "days")
      .format("YYYY-MM-DD");
    setEndDate(mySaturday);
    setStartDate(myMonday);
  };

  const search = async () => {
    setMasses([]);
    setLoading(true);
    setLoaded(false);
    setError("");
    try {
      const resp = await axios.get(
        `/api/mass/instances?start=${startDate}&end=${endDate}`
      );
      setMasses(resp.data);
    } catch (err) {
      setError(err?.response?.data?.error || "Error retrieving information");
    } finally {
      setLoaded(true);
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Reports</h2>
      <Card>
        <Card.Body className="new-intention-card">
          <Card.Title>Bulletin Printout</Card.Title>
          <hr />
          <Form>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Start Date (Monday)</Form.Label>
                  <Form.Control
                    value={startDate}
                    type="date"
                    onChange={chooseStartDate}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formLastName">
                  <Form.Label>End Date (Sunday)</Form.Label>
                  <Form.Control
                    value={endDate}
                    type="date"
                    readOnly={true}
                    onChange={(event) => {
                      setEndDate(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="intentions-by-presider-button-group">
            <Button disabled={!startDate || !endDate} onClick={search}>
              {loading ? <Spinner animation="border" size="sm" /> : "Search"}
            </Button>
            <Button
              style={{
                visibility:
                  Object.keys(masses).length > 0 ? "visible" : "hidden",
              }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </div>
          {(loaded || error) && (
            <div>
              <IntentionsTable masses={masses} error={error} />
            </div>
          )}
        </Card.Body>
      </Card>
      <div style={{ display: "none" }}>
        <BulletinInsertPrintout
          ref={componentRef}
          masses={groupMassesByDay(masses)}
        />
      </div>
    </div>
  );
}

export default BulletinPrintout;
